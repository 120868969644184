import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {IApiAddGruppo, IApiAdminSignUp, IApiChangeGruppoDeleteStatus, IApiError, IApiEventGetModeratoriResponse, IApiGetCustomFieldsResponse, IApiResponse, IApiUpdateGruppo, IAssignCustomField, ICustomField, IEvento, IEventUserUpdateRequest, IGlobalCounter, IGruppo, ILandingLayout, IProgramActivity, IRole, IUser, IUserAdmin} from '../interfaces';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {IQrcode} from '../interfaces/IQrCode';
import {INotification} from '../interfaces/INotification';
import {ISupportMessage, ISupportUser} from '../interfaces/ISupportMessage';
import {IApiResponseQrCodeDecode} from '../interfaces/IApiResponseQrCodeDecode';
import {IMasterclass} from '../interfaces/IMasterclass';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {IContattoUtile} from '../interfaces/IContattoUtile';
import {IMappa, IMappaCategoria, IMappaConfig} from '../interfaces/IMappa';

/**
 * Esegue una chiamata API e gestisce gli errori.
 * @param {Promise<any>} apiCall - La chiamata API da eseguire.
 * @returns {Promise<any>} - Una promessa con il risultato dell'operazione.
 */
async function executeApiCall(apiCall: Promise<any>): Promise<any> {
    try {
        const response = await apiCall;
        if (response.code === 'code/ok') {
            return response;
        } else {
            throw response;
        }
    } catch (error) {
        throw error;
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    gruppi: ApiGruppi;
    eventoUsers: ApiEventUser;
    eventoModeratori: ApiEventModeratori;
    adminUser: ApiAdminUser;
    customField: ApiCustomField;
    event: ApiEvent;
    email: ApiEmail;
    landing: ApiLanding;
    auth: ApiAuth;
    utils: ApiUtils;
    role: ApiRole;
    program: ApiProgram;
    tappe: ApiTappe;
    qrcode: ApiQrcode;
    masterclass: ApiMasterclass;
    contattiUtili: ApiContattiUtili;
    mappe: ApiMappe;
    notifications: ApiNotifications;
    supportMessages: ApiSupportMessages;
    supportNumbers: ApiSupportNumbers;
    supportTokens: ApiSupportTokens;
    utilsApi: ApiUtilsApi;

    constructor(private fns: AngularFireFunctions, private http: HttpClient, private authService: AuthService, private fs: AngularFirestore) {
        this.utils = new ApiUtils(this.authService);
        this.gruppi = new ApiGruppi(this.fns, this.utils);
        this.eventoUsers = new ApiEventUser(this.fns, this.utils, this.authService);
        this.adminUser = new ApiAdminUser(this.fns, this.utils, this.authService);
        this.customField = new ApiCustomField(this.fns, this.utils);
        this.event = new ApiEvent(this.fns, this.utils, this.authService);
        this.eventoModeratori = new ApiEventModeratori(this.fns, this.utils);
        this.email = new ApiEmail(this.http, this.fns, this.utils, this.authService);
        this.landing = new ApiLanding(this.fns);
        this.auth = new ApiAuth(this.fns, this.utils);
        this.role = new ApiRole(this.fns, this.utils, this.authService);
        this.program = new ApiProgram(this.fns, this.utils);
        this.tappe = new ApiTappe(this.fns, this.utils);
        this.qrcode = new ApiQrcode(this.fns, this.utils);
        this.notifications = new ApiNotifications(this.fns, this.utils);
        this.supportMessages = new ApiSupportMessages(this.fns, this.utils);
        this.masterclass = new ApiMasterclass(this.fns, this.utils, this.fs);
        this.contattiUtili = new ApiContattiUtili(this.fns, this.utils);
        this.mappe = new ApiMappe(this.fns, this.utils);
        this.supportNumbers = new ApiSupportNumbers(this.fns, this.utils);
        this.supportTokens = new ApiSupportTokens(this.fns, this.utils);
        this.utilsApi = new ApiUtilsApi(this.fns, this.utils);
    }
}

class ApiUtils {
    constructor(private authService: AuthService) {
    }

    async checkError(error: any): Promise<any> {
        let status = true;
        console.error('Errore nella richiesta', error);
        switch (error.code) {
            case 'code/session_expired':
                this.authService.logOut();
                await new Promise(res => setTimeout(() => {
                }, 1000));
                status = false;
                break;
            case 'unauthenticated':
                this.authService.logOut();
                status = false;
                break;
            default:
                status = true;
        }
        return Promise.resolve(status);
    }
}

class ApiAdminUser {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils, private authService: AuthService) {
    }

    /**
     * Registro l'utente
     * @param _email Email dell'utente da registrare
     * @param _ruolo Ruolo dell'utente
     * @param _id_eventi Lista di eventi associati all'utente
     * @param _access_type Tipo di verifica accesso
     * @param _moduli Moduli da aggiungere all'utente
     */
    adminSignUp(_email: string, _ruolo: number, _id_eventi: string[], _access_type: 'email' | 'phone' | 'none', _moduli: string[], profile_expiration: any): Promise<IApiResponse | IApiError> {
        if (!this.checkEmail(_email)) {
            return Promise.reject({code: 400, message: 'Email non valida.'});
        }
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable<IApiAdminSignUp>('app/admins/adminSignUp');
            return callable({email: _email, ruolo: _ruolo, id_eventi: _id_eventi, access_type: _access_type, moduli: _moduli, profile_expiration}).toPromise().then(async res => {
                if (res.code === 'auth/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    adminSignUpFinish(user: Partial<IUserAdmin>): Promise<IApiResponse | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable<Partial<IUserAdmin>>('app/adminReg/adminSignUpFinish');
            return callable(user).toPromise().then(async res => {
                if (res.code === 'auth/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Restituisco tutti gli utenti Auth
     */
    getUsersAdmin(): Promise<IUserAdmin[]> {
        return new Promise<IUserAdmin[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/admins/getAdminUsers');
            return callable({}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.users);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });

        });
    }

    /**
     * Restituisco un'utente specifico in base allo uid
     * @param _uid id dell'utente
     */
    getUserAdmin(_uid: string): Promise<IUserAdmin> {
        return new Promise<IUserAdmin>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/admins/getAdminUser');
            return callable({uid: _uid}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.user);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Aggiorno un utente admin
     * @param user oggetto dell'utente da aggiornare
     */
    updateUserAdmin(user: Partial<IUserAdmin>): Promise<Partial<IUserAdmin>> {
        return new Promise<Partial<IUserAdmin>>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/admins/updateAdminUser');
            return callable(user).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    changeRoleUserAdmin(uid: string, role: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/admins/changeRoleAdminUser');
            return callable({uid, role}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Verifico se l'email è un formato corretto
     * @param email Email da verificare
     */
    checkEmail(email: string): boolean {
        const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
    }

}

class ApiCustomField {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    /**
     * Aggiungo un nuovo custom field dentro l'evento
     * @param data CustomField object
     * @param _event_id id dell'evento
     */
    addCustomField(_event_id: string, data: ICustomField | any[]): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/setCustomFields');
            return callable({event_id: _event_id, custom_fields: data}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Restituisco la lista dei custom field associati a un evento
     * @param _event_id id dell'evento
     * @param _from tipologia di customField da restituire
     * @param _group_id id del gruppo
     */
    getCustomFields(_event_id: string, _from?: 'users' | 'group' | 'hostess' | 'on_site', _group_id?: string): Promise<IApiGetCustomFieldsResponse | ICustomField[]> {
        return new Promise<IApiGetCustomFieldsResponse | ICustomField[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getCustomFields');
            let d: any = {event_id: _event_id};
            if (_from) {
                d = {event_id: _event_id, from: _from, group_id: _group_id};
            }
            return callable(d).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.custom_fields);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getCustomFieldsUserList(_event_id: string): Promise<IApiGetCustomFieldsResponse | ICustomField[]> {
        return new Promise<IApiGetCustomFieldsResponse | ICustomField[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getCustomFieldsUserList');
            return callable({event_id: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.cf);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Rimuovo un custom filed specifico
     * @param _event_id id dell'evento da cui rimuovere il custom field
     * @param data Oggetto contenente il custom field
     */
    removeCustomField(_event_id: string, data: ICustomField): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/removeCustomFields');
            return callable({event_id: _event_id, field_name: data}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    removePhotoOrFile(_event_id: string, _user_id: string, _field_name: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/delField');
            return callable({event_id: _event_id, id_user: _user_id, field_name: _field_name}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    assignCustomField(data: IAssignCustomField): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/assignCustomFields');
            return callable(data).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });

        });
    }

    importCustomFields(_event_id: string, data: ICustomField | any[]): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/importCustomFields');
            return callable({event_id: _event_id, custom_fields: data}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiEvent {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils, private authService: AuthService) {
    }

    getCounter(_event_id: string): Promise<IGlobalCounter> {
        return new Promise<IGlobalCounter>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getCounter');
            return callable({event_id: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.counters);
                } else {
                    reject(res.code);
                }
            }).catch(err => {
                reject(err.code);
            });
        });
    }

    getUserEvent(_uid: string, _type?: string): Promise<IEvento[]> {
        // console.log('getUserEvent');
        return new Promise<IEvento[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getEventiUser');
            return callable({uid: _uid, type: _type}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.events);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getUsersOnSite(_event_id: string): Promise<Partial<IUser[]>> {
        return new Promise<Partial<IUser[]>>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getUsersOnSite');
            return callable({event_id: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.users);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    setModeratore(_event_id: string, _uid: string, type: 'add' | 'remove'): Promise<IEvento | boolean> {
        return new Promise<IEvento | boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/setModeratore');
            return callable({event_id: _event_id, uid: _uid, operation: type}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.event);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    setModeratoreOnSite(_event_id: string, _uid: string, type: 'add' | 'remove'): Promise<IEvento | boolean> {
        return new Promise<IEvento | boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/setOnSiteModeratore');
            return callable({event_id: _event_id, uid: _uid, operation: type}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.event);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Aggiungo un nuovo evento
     * @param evento Oggetto dell'evento da aggiungere
     */
    addEvent(evento: IEvento): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/addEvento');
            return callable(evento).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Aggiorno un evento esistente
     * @param evento Oggetto dell'evento modificato
     */
    updateEvent(evento: IEvento): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/updateEvento');
            return callable(evento).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Restituisco tutti gli eventi
     */
    getAllEvents(): Promise<IEvento[]> {
        return new Promise<IEvento[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getEventi');
            return callable({}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.events);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Restituisco tutti gli eventi associati a un utente onsite
     */
    getAllEventsOnSite(): Promise<IEvento[]> {
        return new Promise<IEvento[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getEventiOnSite');
            return callable({}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.events);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getAllEventsAvailableForUser(_uid: string, _type?: string): Promise<IEvento[]> {
        return new Promise<IEvento[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getEventiNotUserAssociated');
            return callable({uid: _uid, type: _type}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.events);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getEvento(_event_id: string): Promise<IEvento> {
        return new Promise<IEvento>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getEvento');
            return callable({event_id: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.evento);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    addConfigurazioniHostess(_event_id: string, _group_id: string, _nome_modello: string, _col_selected: any, type?: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/addConfigurazioneHostess');

            const obj: any = {event_id: _event_id, group_id: _group_id, nome_modello: _nome_modello, col_selected: _col_selected};
            if (type) {
                obj.type = type;
            }

            return callable(obj).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getConfigurazioniHostess(_event_id: string, type?: string): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getConfigurazioneHostess');
            const obj: any = {event_id: _event_id};
            if (type) {
                obj.type = type;
            }
            return callable(obj).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.configurazioni);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    updateConfigurazioniHostess(_event_id: string, _id_configurazione: string, _group_id: string, _col_selected: any, type?: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/updateConfigurazioneHostess');

            const obj: any = {event_id: _event_id, id_configurazione: _id_configurazione, group_id: _group_id, col_selected: _col_selected};
            if (type) {
                obj.type = type;
            }

            return callable(obj).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteConfigurazioniHostess(_event_id: string, _id_configurazione: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/deleteConfigurazioneHostess');
            return callable({event_id: _event_id, id_configurazione: _id_configurazione}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    checkEventAutorization(_event_id: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/checkEventAutorization');
            return callable({event_id: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    checkAuthorization(_modulo: string, _moduliToken: string): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/checkAuthorization');
            return callable({modulo: _modulo, moduliToken: _moduliToken}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else {
                    resolve(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getDashboardData(_event_id: string, _group_ids: string[], _startDate: string, _endDate: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getDashboardData');
            return callable({event_id: _event_id, group_ids: _group_ids, startDate: _startDate, endDate: _endDate}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

}

class ApiEventUser {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils, private authService: AuthService) {
    }

    checkBeforeAdd(_event_id: string, _users: Partial<IUser>[]): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/checkBeforeAdd');
            return callable({event_id: _event_id, users: _users}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    addUsers(_event_id: string, _users: Partial<IUser>[], _nome_file: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/addUsers');
            return callable({event_id: _event_id, users: _users, nome_file_importazione: _nome_file}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getUsers(_event_id: string, _id_gruppi?: string): Promise<IUser[]> {
        // console.log('getUsers');
        return new Promise<IUser[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getUsers');
            return callable({event_id: _event_id, id_gruppi: _id_gruppi ? _id_gruppi : ''}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.users);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getUser(_event_id: string, _email: string): Promise<any> {
        // console.log('getUser');
        return new Promise<any>(async (resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getUser');
            return callable({event_id: _event_id, email: _email}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.user);
                } else if (res.code === 'code/not_found') {
                    console.log('Errore, utente non trovato', res.code);
                    reject(res);
                } else {
                    throw new Error(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    updateUser(data: IEventUserUpdateRequest): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/updateUser');
            return callable(data).toPromise().then(async res => {
                if (res.code === 'code/ok' || res.code === 'code/masterclass_full') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    checkUserReg(_event_id: string, _token_reg: string): Promise<boolean | any> {
        return new Promise<boolean | IUser>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/checkUserReg');
            return callable({event_id: _event_id, token_reg: _token_reg}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else if (res.code === 'code/ok_incomplete') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getUserByScan(event_id: string, token_reg: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getUserByScan');
            return callable({event_id, token_reg}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.user);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Genera gli App Token per gli utenti scelti
     *
     * @param _event_id ID Evento
     * @param _users Lista utenti a cui creare l'app token
     * @param _force Forza la rigenerazione nel caso in cui l'app_token esistesse.
     */
    generateAppTokens(_event_id: string, _users: any[], _force = false): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/users/generateAppTokens');
            return callable({event_id: _event_id, users: _users, force: _force}).toPromise().then(async res => {
                // console.log(res);
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                // console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Genera i QR Code personali per gli utenti selezionati
     *
     * @param _event_id ID Evento
     * @param _users Lista utenti a cui creare l'app token
     * @param _force Forza la rigenerazione nel caso in cui l'app_token esistesse.
     */
    generateProfileQrCodes(_event_id: string, _users: any[], _force = false): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/users/generateProfileQrCodes');
            return callable({event_id: _event_id, users: _users, checkDup: _force}).toPromise().then(async res => {
                // console.log(res);
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                // console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

}

class ApiGruppi {

    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    getGruppi(_event_id: string): Promise<IGruppo[]> {
        // console.log('getGruppi');
        return new Promise<IGruppo[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getGruppiEvento');
            return callable({event_id: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.gruppi);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getGruppo(_event_id: string, _id: string): Promise<IGruppo> {
        // console.log('getGruppo');
        return new Promise<IGruppo>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getGruppo');
            return callable({event_id: _event_id, id: _id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.gruppo);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    addGruppo(_gruppo: IApiAddGruppo): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/addGruppo');
            return callable(_gruppo).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.id);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    updateGruppo(_updateGruppo: IApiUpdateGruppo): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/updateGruppo');
            return callable(_updateGruppo).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    changeGruppoDeleteStatus(gruppoDeleteStatus: IApiChangeGruppoDeleteStatus): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/changeGruppoDeleteStatus');
            return callable(gruppoDeleteStatus).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiEventModeratori {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    /**
     * Ottengo la lista dei moderatori
     * @param _event_id ID Evento
     * @param _mode Tipo di lista da richiedere
     * @param _showAll Mostra tutti, compresi quelli non verificati
     */
    getModeratori(_event_id: string, _mode: 'all' | 'only' | 'not_in', _showAll: boolean = false): Promise<IApiEventGetModeratoriResponse[]> {
        // console.log('getModeratori');
        return new Promise<IApiEventGetModeratoriResponse[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/api/getModeratori');
            return callable({event_id: _event_id, mode: _mode, showNotVerified: _showAll}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.users);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiEmail {
    constructor(private http: HttpClient, private fns: AngularFireFunctions, private utils: ApiUtils, private authService: AuthService) {
    }

    getTemplates(_key: string): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/sg/getTemplates');
            return callable({key: _key}).toPromise().then(async res => {
                if (res.code === 'sg/ok') {
                    resolve(res.data);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    sendEmailCustom(_templateId: string, _event_id: string, _emailFrom: string, _subject: string, _placeholder: string, _body: string, _users: { email: string, token_reg: string }[], _attachments: any[], replyTo: string, displayName: string, temporizzazione?: number): Promise<any> {
        return new Promise<any>(((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/sg/sendEmails');
            let obj;
            if (temporizzazione) {
                obj = {event_id: _event_id, emailFrom: _emailFrom, templateId: _templateId, subject: _subject, placeholder: _placeholder, body: _body, users: _users, attachments: _attachments, replyTo, displayName, temporizzazione};
            } else {
                obj = {event_id: _event_id, emailFrom: _emailFrom, templateId: _templateId, subject: _subject, placeholder: _placeholder, body: _body, users: _users, attachments: _attachments, replyTo, displayName};
            }
            return callable(obj).toPromise().then(async res => {
                if (res.code === 'sg/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(err => {
                reject(err);
            });
        }));
    }

    sendEmailTemplate(_templateId: string, _event_id: string, _emailFrom: string, _email_type: string, _users: { email: string, token_reg: string }[], _attachments: any[], replyTo: string, displayName: string, temporizzazione?: number): Promise<any> {
        return new Promise<any>(((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/sg/sendEmails');
            let obj;
            if (temporizzazione) {
                obj = {templateId: _templateId, event_id: _event_id, emailFrom: _emailFrom, email_type: _email_type, users: _users, attachments: _attachments, replyTo, displayName, temporizzazione};
            } else {
                obj = {templateId: _templateId, event_id: _event_id, emailFrom: _emailFrom, email_type: _email_type, users: _users, attachments: _attachments, replyTo, displayName};
            }
            return callable(obj).toPromise().then(async res => {
                if (res.code === 'sg/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(err => {
                reject(err);
            });
        }));
    }

    /**
     * Invio un messaggio agli utenti selezionati
     *
     * @param event_id ID Evento
     * @param numbers Numeri a cui inviare il messaggio
     * @param body Corpo del messaggio da inviare
     */
    sendSms(event_id: string, numbers: string[], body: string): Promise<any> {
        return new Promise<any>(((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/sg/sendSms');
            return callable({event_id, numbers, body}).toPromise().then(async res => {
                if (res.code === 'sg/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(err => {
                reject(err);
            });
        }));
    }

}

class ApiLanding {
    constructor(private fns: AngularFireFunctions) {
    }

    setLanding(_event_id: string, _data: ILandingLayout): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/landing/setLanding');
            return callable({event_id: _event_id, data: _data}).toPromise().then(() => {
                resolve(true);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getLanding(_event_id: string): Promise<ILandingLayout> {
        return new Promise((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/landing/getLanding');
            return callable({event_id: _event_id}).toPromise().then(res => {
                resolve(res.landing);
            }).catch(err => {
                reject(err);
            });
        });
    }

}

class ApiAuth {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    /**
     * Invia nuovamente il codice di accesso all'utente.
     * @param {string} email - L'indirizzo email dell'utente.
     * @returns {Promise<any>} - Una promessa con il risultato dell'invio del codice.
     */
    async resendLoginCode(email: string): Promise<any> {
        try {
            const callable = this.fns.httpsCallable('app/auth/resendAccessToken');
            return await callable({email}).toPromise();
        } catch (err) {
            console.error(err);
            throw err; // Rilancio l'errore per gestirlo più in alto nella catena delle promesse
        }
    }


    /**
     * Controlla il codice di accesso per l'utente.
     * @param {string} email - L'indirizzo email dell'utente.
     * @param {number} token - Il token da verificare.
     * @returns {Promise<any>} - Una promessa con il risultato della verifica.
     */
    async checkLoginCode(email: string, token: number): Promise<any> {
        try {
            const callable = this.fns.httpsCallable('app/auth/checkAccessToken');
            const res = await callable({email, token}).toPromise();

            if (res.code === 'code/ok') {
                return {code: res.code, token: res.token};
            } else {
                return {code: res.code, message: res.message};
            }
        } catch (err) {
            console.error(err);
            throw {code: err.code, message: err.message};
        }
    }


    /**
     * Resetta la password dell'utente.
     * @param {string} email - L'indirizzo email dell'utente.
     * @returns {Promise<boolean>} - Una promessa che indica se il reset della password è riuscito.
     */
    async resetPassword(email: string): Promise<boolean> {
        try {
            const callable = this.fns.httpsCallable('app/auth/resetPassword');
            const res = await callable({email}).toPromise();

            if (res.code === 'code/ok') {
                return true;
            } else {
                throw res;
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    /**
     * Verifica il codice per il reset della password.
     * @param {string} token - Il token per il reset della password.
     * @returns {Promise<string>} - Una promessa con l'email associata al token.
     */
    async verifyCodeResetPassword(token: string): Promise<string> {
        try {
            const callable = this.fns.httpsCallable('app/auth/verifyCodeResetPassword');
            const res = await callable({token}).toPromise();

            if (res.code === 'code/ok') {
                return res.email;
            } else {
                throw new Error('Errore durante la verifica del codice di reset della password');
            }
        } catch (err) {
            console.error(err);
            throw err; // Rilancio l'errore originale per gestirlo più in alto nella catena delle promesse
        }
    }

    /**
     * Cambia la password utilizzando un token.
     * @param {string} token - Il token per il reset della password.
     * @param {string} password - La nuova password dell'utente.
     * @returns {Promise<boolean>} - Una promessa che indica se il cambio della password è riuscito.
     */
    async changePasswordWithToken(token: string, password: string): Promise<boolean> {
        try {
            const callable = this.fns.httpsCallable('app/auth/changePasswordWithToken');
            const res = await callable({token, password}).toPromise();

            if (res.code === 'code/ok') {
                return true;
            } else {
                throw res;
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
}

class ApiRole {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils, private authService: AuthService) {
    }

    /**
     * Aggiunge un ruolo.
     * @param {string} _nome - Il nome del ruolo.
     * @param {number} _index - L'indice del ruolo.
     * @returns {Promise<IApiResponse | IApiError>} - Una promessa con il risultato dell'aggiunta del ruolo.
     */
    addRole(_nome: string, _index: number): Promise<IApiResponse | IApiError> {
        const callable = this.fns.httpsCallable('app/role/addRole');
        const apiCall = callable({nome: _nome, index: _index}).toPromise();
        return executeApiCall(apiCall);
    }

    /**
     * Aggiorna un ruolo esistente.
     * @param {string} _nome - Il nuovo nome del ruolo.
     * @param {number} _index - Il nuovo indice del ruolo.
     * @param {string} _id - L'ID del ruolo da aggiornare.
     * @returns {Promise<IApiResponse | IApiError>} - Una promessa con il risultato dell'aggiornamento del ruolo.
     */
    updateRole(_nome: string, _index: number, _id: string): Promise<IApiResponse | IApiError> {
        const callable = this.fns.httpsCallable('app/role/updateRole');
        const apiCall = callable({nome: _nome, index: _index, id: _id}).toPromise();
        return executeApiCall(apiCall);
    }

    /**
     * Ottiene la lista dei ruoli.
     */
    listRoles(): Promise<{code: string, roles: IRole[], status: number}> {
        const callable = this.fns.httpsCallable('app/role/listRoles');
        const apiCall = callable({}).toPromise();
        return executeApiCall(apiCall);
    }

    /**
     * Rimuove un ruolo.
     * @param {number} _index - L'indice del ruolo da rimuovere.
     * @param {string} _id - L'ID del ruolo da rimuovere.
     * @returns {Promise<IApiResponse | IApiError>} - Una promessa con il risultato della rimozione del ruolo.
     */
    removeRole(_index: number, _id: string): Promise<IApiResponse | IApiError> {
        const callable = this.fns.httpsCallable('app/role/removeRole');
        const apiCall = callable({index: _index, id: _id}).toPromise();
        return executeApiCall(apiCall);
    }
}

class ApiProgram {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    addActivity(eventId: string, programId: string, activity: IProgramActivity): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/addActivity');
            return callable({eventId, programId, activity}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    editActivity(eventId: string, programId: string, activity: IProgramActivity[]): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/editActivity');
            return callable({eventId, programId, activity}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getPrograms(eventId: string): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/getPrograms');
            return callable({eventId}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.programs);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Aggiungere un program
     * @param eventId
     * @param program
     */
    addProgram(eventId: string, program: { nome: string, id_gruppi: string[] }): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/addProgram');
            return callable({eventId, program}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.programId);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Rimuovi un program dato un array di id
     * @param eventId
     * @param programIds
     */
    removePrograms(eventId: string, programIds: string[]): Promise<any | IApiError> {
        return new Promise<boolean | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/removePrograms');
            return callable({eventId, programIds}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(true);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Modifica un program
     * @param eventId
     * @param program
     */
    editProgram(eventId: string, program: { id: string, nome: string, id_gruppi: string[] }): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/editProgram');
            return callable({eventId, program}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.programId);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    addPdf(eventId: string, programId: string, pdf: { path: string; url: string }): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/addPdf');
            return callable({eventId, programId, pdf}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    removePdf(eventId: string, programId: string): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/program/removePdf');
            return callable({eventId, programId}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

}

class ApiTappe {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    createTappa(eventId: string, tappa: any): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/tappe/createTappa');
            return callable({eventId, tappa}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    editTappa(eventId: string, idTappa: string, tappa: any): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/tappe/editTappa');
            return callable({eventId, idTappa, tappa}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getTappe(eventId: string): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/tappe/getTappe');
            return callable({eventId}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.tappe);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteTappa(eventId: string, idTappa: string): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/tappe/deleteTappa');
            return callable({eventId, idTappa}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

}

class ApiQrcode {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    /**
     * Crea i codici QR del profilo per gli utenti selezionati
     *
     * Payload JSON
     * {
     *     "data": {
     *         "event_id": string,
     *         "users": [
     *             {
     *                 "uid": string,
     *                 "nome": string,
     *                 "cognome": string,
     *                 "email": string
     *             }
     *         ]
     *     }
     * }
     * @param _event_id ID evento
     * @param _users Lista utenti a cui creare il codice qr del profilo
     * @param _checkDup Imposta il controllo dei duplicati (default true)
     */
    generateProfileQrCodes(_event_id: string, _users: { uid: string, nome: string, cognome: string, email: string }[], _checkDup: boolean = true): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/users/generateProfileQrCodes');
            return callable({event_id: _event_id, users: _users, checkDup: _checkDup}).toPromise().then(async res => {
                // console.log(res);
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    addQrCode(eventId: string, userId: string, qrcode: IQrcode): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/qrcode/addQrCode');
            return callable({eventId, userId, qrcode}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getQrCodes(eventId: string, userId: string): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/qrcode/getQrCodes');
            return callable({eventId, userId}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.qrcodes);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteQrcode(eventId: string, userId: string, qrcodeId: string): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/qrcode/deleteQrCode');
            return callable({eventId, userId, qrcodeId}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    editQrcode(eventId: string, userId: string, qrcode: IQrcode): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/qrcode/editQrCode');
            return callable({eventId, userId, qrcode}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    editQrcodeOrderList(eventId: string, userId: string, qrcode: any[]): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/qrcode/editQrCodeOrderList');
            return callable({eventId, userId, qrcode}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    decode(encodedText: string): Promise<IApiResponseQrCodeDecode> {
        return new Promise<IApiResponseQrCodeDecode>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/qrcode/decodeQrCode');
            return callable({qrCodeData: encodedText}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiNotifications {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    addNotification(notification: INotification): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/notifications/addNotification');
            return callable(notification).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    await this.utils.checkError(res);
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                reject(err);
            });
        });
    }

    deleteNotifications(notificationId: string): Promise<IApiResponse | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/notifications/deleteNotification');
            const payload = {notification: {id: notificationId}};
            return callable(payload).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getNotifications(eventId: string): Promise<INotification[] | IApiError> {
        return new Promise<INotification[] | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/notifications/getNotifications');
            return callable({eventId}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.notifications);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiSupportMessages {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    // TODO ALLINEARE CON LE NUOVE API PER L'INVIO DI NOTIFICHE E MESSAGGI DA APP
    addMessage(_user_id, _chat_id: string, _message: string, _chatData?: any): Promise<any | IApiError> {
        return new Promise<IApiResponse | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/addMessage');
            return callable({userId: _user_id, chatId: _chat_id, message: _message}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    await this.utils.checkError(res);
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                reject(err);
            });
        });
    }

    /**
     *
     * @param _chat_id ID chat
     */
    getUserMessages(_chat_id: string): Promise<ISupportMessage[] | IApiError> {
        return new Promise<ISupportMessage[] | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/getUserMessages');
            return callable({chatId: _chat_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.messages);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getChatUsers(_event_id: string): Promise<ISupportUser[] | IApiError> {
        return new Promise<ISupportUser[] | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/getChatUsers');
            return callable({eventId: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res.supportChatUsers);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    setAllReaded(_chat_id: string): Promise<ISupportUser[] | IApiError> {
        return new Promise<ISupportUser[] | IApiError>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/updateAllReaded');
            return callable({chatId: _chat_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    reject(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiMasterclass {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils, private fs: AngularFirestore) {
    }

    /**
     * Esegue l'accesso di un utente ad una masterclass
     *
     * Payload JSON
     * {
     *     "data": {
     *         "eventId": string,
     *         "userId": string,
     *         "masterclassId": string
     *     }
     * }
     * @param _event_id ID evento
     * @param _userId ID Utente
     * @param _masterclassId ID Masterclass
     */
    accessMasterclass(_event_id: string, _userId: string, _masterclassId: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/masterclass/accessMasterclass');
            return callable({eventId: _event_id, userId: _userId, masterclassId: _masterclassId}).toPromise().then(async res => {
                // console.log(res);
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    /**
     * Scarica la lista di tutte le masterclass
     *
     * Payload JSON
     * {
     *     "data": {
     *         "eventId": string
     *     }
     * }
     * @param _event_id ID evento
     */
    getMasterclasses(_event_id: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/masterclass/getMasterclasses');
            return callable({eventId: _event_id}).toPromise().then(async res => {
                // console.log(res);
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    getMasterclassesObservable(_event_id: string) {
        return this.fs.collection('events/' + _event_id + '/masterclasses').valueChanges({idField: 'id'});
    }

    getMasterclassObservable(_event_id: string, _masterclassId: string) {
        return this.fs.doc('events/' + _event_id + '/masterclasses/' + _masterclassId).valueChanges();
    }

    addMasterclass(_event_id: string, masterclass: IMasterclass): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/masterclass/addMasterclass');
            return callable({eventId: _event_id, masterclass}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    editMasterclass(_event_id: string, masterclassId: string, masterclass: IMasterclass): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/masterclass/editMasterclass');
            return callable({eventId: _event_id, masterclassId, masterclass}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteMasterclasses(_event_id: string, masterclassIds: string[]): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/masterclass/deleteMasterclasses');
            return callable({eventId: _event_id, masterclassIds}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    masterclassUserChange(_event_id: string, _masterclassPrec: string, _masterclassNew: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/masterclass/masterclassUserChange');
            return callable({eventId: _event_id, masterclassPrec: _masterclassPrec, masterclassNew: _masterclassNew}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiSupportTokens {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    checkSupportToken(_event_id: string, _user_id: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/checkSupportToken');
            return callable({eventId: _event_id, userId: _user_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    manageSupportToken(_event_id: string, _token: string, _user_id: string, _removeOtherUserDevices?: boolean): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            let obj;
            if (_removeOtherUserDevices) {
                obj = {
                    eventId: _event_id,
                    token: _token,
                    userId: _user_id,
                    removeOtherUserDevices: _removeOtherUserDevices
                };
            } else {
                obj = {
                    eventId: _event_id,
                    token: _token,
                    userId: _user_id
                };
            }
            const callable = this.fns.httpsCallable('app/support/manageSupportToken');
            return callable(obj).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteSupportToken(_event_id: string, _token: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/deleteSupportToken');
            return callable({eventId: _event_id, token: _token}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiSupportNumbers {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    addSupportNumber(_event_id: string, _number: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            let obj = {
                eventId: _event_id,
                number: _number
            };
            const callable = this.fns.httpsCallable('app/support/addSupportNumber');
            return callable(obj).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteSupportNumber(_event_id: string, _number: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/deleteSupportNumber');
            return callable({eventId: _event_id, number: _number}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    checkSupportNumber(_event_id: string, _number: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/support/checkSupportNumber');
            return callable({eventId: _event_id, number: _number}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiUtilsApi {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    encodeBase64Url(stringa: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/utils/encodeBase64Url');
            return callable({string: stringa}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiContattiUtili {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    getContatti(_event_id: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/contatti_utili/getContatti');
            return callable({eventId: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    addContatto(_event_id: string, _contatto: IContattoUtile): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/contatti_utili/addContatto');
            return callable({eventId: _event_id, contatto: _contatto}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    editContatto(_event_id: string, _contatto: IContattoUtile, _nuovoContatto: IContattoUtile): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/contatti_utili/editContatto');
            return callable({eventId: _event_id, contatto: _contatto, nuovoContatto: _nuovoContatto}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteContatti(_event_id: string, _contatti: IContattoUtile[]): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/contatti_utili/deleteContatti');
            return callable({eventId: _event_id, contatti: _contatti}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}

class ApiMappe {
    constructor(private fns: AngularFireFunctions, private utils: ApiUtils) {
    }

    getMappe(_event_id: string): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/mappe/getMappe');
            return callable({eventId: _event_id}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    addMappa(_event_id: string, _mappa: IMappa, _geocode: boolean): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/mappe/addMappa');
            return callable({eventId: _event_id, mappa: _mappa, geocode: _geocode}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    editMappa(_event_id: string, _mappa: IMappa, _nuovaMappa: IMappa, _geocode: boolean): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/mappe/editMappa');
            return callable({eventId: _event_id, mappa: _mappa, nuovaMappa: _nuovaMappa, geocode: _geocode}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    deleteMappe(_event_id: string, _mappe: IMappa[]): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/mappe/deleteMappe');
            return callable({eventId: _event_id, mappe: _mappe}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                console.error(err);
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    configMappa(_event_id: string, _config: IMappaConfig): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/mappe/configMappa');
            return callable({eventId: _event_id, config: _config}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }

    configLegenda(_event_id: string, _config: IMappaCategoria[]): Promise<IApiResponse> {
        return new Promise<IApiResponse>((resolve, reject) => {
            const callable = this.fns.httpsCallable('app/mappe/configLegenda');
            return callable({eventId: _event_id, config: _config}).toPromise().then(async res => {
                if (res.code === 'code/ok') {
                    resolve(res);
                } else {
                    await this.utils.checkError(res);
                    resolve(res);
                }
            }).catch(async err => {
                await this.utils.checkError(err);
                reject(err);
            });
        });
    }
}
