import {Component,OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-support-chats',
  templateUrl: './support-not-authorized.component.html',
  styleUrls: ['./support-not-authorized.component.scss']
})
export class SupportNotAuthorizedComponent {

  constructor(private auth: AuthService) { }

  loginCliked(){
    this.auth.logOutSupport();
  }

}
