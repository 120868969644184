import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ng-uikit-pro-standard';
import {ICustomField, IGruppo, IUser} from '../../interfaces/';
import {ApiService} from '../../services/api.service';

@Component({
	selector: 'app-user-view',
	templateUrl: './user-view.component.html',
	styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
	@ViewChild('modalViewUser') modalViewUser: ModalDirective;
	@Input() event_id: string;

	userSelected: IUser | null = null;
	loadUserView = false;
	customFieldsEvent: { user: ICustomField[] | any[], hostess: ICustomField[] | any[], gruppo: any[], on_site: [] | any[] } = {user: [], hostess: [], gruppo: [], on_site: []};

	constructor(private api: ApiService) {
	}

	ngOnInit(): void {
	}

	openModal(userSelected: IUser, customFields: any[], allCf: ICustomField[]): void {
		this.userSelected = userSelected;

		this.customFieldsEvent.user = [];
		this.customFieldsEvent.hostess = [];
		this.customFieldsEvent.gruppo = [];
		this.customFieldsEvent.on_site = [];

		this.loadCf(customFields, allCf);

		this.loadUserView = true;
		this.modalViewUser.show();
	}

	loadCf(customFields: any[], allCf: ICustomField[]){

		// Cf per Users
		const UsersIndex = customFields.findIndex(x => x.type === 'users');
		let UsersCfs = [];
		if(UsersIndex > -1){
			for(let cf of customFields[UsersIndex].options){
				const customField = allCf.find(x => x.field_name === cf.value);
				if(customField){
					UsersCfs.push(customField);
				}
			}
			if(UsersCfs.length > 0){
				this.customFieldsEvent.on_site = [...UsersCfs];
			}
		}

		// Cf per Hostess
		const HostessIndex = customFields.findIndex(x => x.type === 'hostess');
		let hostessCfs = [];
		if(HostessIndex > -1){
			for(let cf of customFields[HostessIndex].options){
				const customField = allCf.find(x => x.field_name === cf.value);
				if(customField){
					hostessCfs.push(customField);
				}
			}
			if(hostessCfs.length > 0){
				this.customFieldsEvent.on_site = [...hostessCfs];
			}
		}

		// Cf per Gruppi
		const GruppoIndex = customFields.findIndex(x => x.type === 'gruppo');
		let GruppoCfs = [];
		if(GruppoIndex > -1){
			for(let cf of customFields[GruppoIndex].options){
				const customField = allCf.find(x => x.field_name === cf.value);
				if(customField){
					GruppoCfs.push(customField);
				}
			}
			if(GruppoCfs.length > 0){
				this.customFieldsEvent.on_site = [...GruppoCfs];
			}
		}

		// Cf per OnSite
		const onSiteIndex = customFields.findIndex(x => x.type === 'on_site');
		let onSiteCfs = [];
		if(onSiteIndex > -1){
			for(let cf of customFields[onSiteIndex].options){
				const customField = allCf.find(x => x.field_name === cf.value);
				if(customField){
					onSiteCfs.push(customField);
				}
			}
			if(onSiteCfs.length > 0){
				this.customFieldsEvent.on_site = [...onSiteCfs];
			}
		}
	}

}
