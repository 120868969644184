import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {AuthService} from './services/auth.service';
import {GlobalService} from './services/global.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'GEvents';
    loaded = false;

    constructor(private router: Router, private auth: AuthService, private globalService: GlobalService) {
        console.log('GEvents version', environment.appVersion);
        // console.log('Use emulators', environment.useEmulators);
        this.checkVersion();
    }

    ngOnInit(): void {

        /* INIZIO FIX per errore Uncaught (in promise) all'interno delle librerie firebase */
        // Abilito il fix solo sulla build production
        if (environment.production) {
            // Salvo il riferimento originale di console.error
            const originalConsoleError = console.error;

            // Sovrascrivo la funzione error della console
            console.error = function (...args) {
                // Testo da nascondere
                const textToHide = 'Error: Uncaught (in promise): FirebaseError: Firebase:';

                // Converto tutti gli argomenti in stringa e verifico se il testo è presente
                if (args.length > 1 && !args[1].toString().includes(textToHide)) {
                    // Se non è presente, chiamo il console.error originale
                    originalConsoleError.apply(console, args);
                }
            };
        }
        /* FINE FIX */

        this.globalService.initialPath = window.location.href.replace(window.location.origin, '');
        this.auth.init().then(() => {
            this.loaded = true;
        });
    }

    isAdmin(): boolean {
        if (this.router.url.includes('admin')) {
            return true;
        }
        return false;
    }

    isOnSite(): boolean {
        if (this.router.url.includes('hostess') || this.router.url.includes('on_site') || this.router.url.includes('scan-qrcode') || this.router.url.includes('support')) {
            return true;
        }
        return false;
    }

    checkVersion(): void {
        const storageVersion = localStorage.getItem('platform-version');
        if (storageVersion) {
            if (environment.appVersion !== storageVersion) {
                localStorage.clear();
                localStorage.setItem('platform-version', environment.appVersion);
                window.location.reload();
            }
        } else {
            localStorage.clear();
            localStorage.setItem('platform-version', environment.appVersion);
            window.location.reload();
        }
    }

}
