import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {

	option = {
		opacity: 1,
		progressBar: true,
		timeout: 3000
	};

	constructor(private toastService: ToastService) {
	}

	/**
	 * Mostra una notifica
	 * @param msg Messaggio da visualizzare
	 * @param title Titolo della notifica
	 * @param callback Funzione da richiamare al click
	 */
	showSuccessNotification(msg: string, title?: string, callback?: () => void): number {
		const toast = this.toastService.success(msg, title, this.option);
		if (callback != null) {
			toast.onTap.subscribe(callback);
		}
		return toast.toastId;
	}

	/**
	 * Mostra una notifica di errore
	 * @param msg Messaggio da visualizzare
	 * @param title Titolo della notifica
	 * @param callback Funzione da richiamare al click
	 */
	showErrorNotification(msg: string, title?: string, callback?: () => void): number {
		const toast = this.toastService.error(msg, title, this.option);
		if (callback != null) {
			toast.onTap.subscribe(callback);
		}
		return toast.toastId;
	}

	/**
	 * Cancella tutte le notifiche sullo schermo
	 */
	clearNotification(id: number | 'all' = 'all'): void {
		// console.log('clearing', id);
		if (id === 'all') {
			this.toastService.clear();
		} else {
			this.toastService.clear(id);
		}
	}
}
