import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

// TODO inutilizzato, creato per i custom field dinamici

@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[requiredSymbol]'
})
export class RequiredInputDirective implements AfterViewInit {

	@Input() isRequired: boolean;

	constructor(private el: ElementRef, private renderer: Renderer2) {
	}

	ngAfterViewInit(): void {
		const parent = this.renderer.parentNode(this.el.nativeElement);
		if (parent.getElementsByTagName('label').length && this.isRequired) {
			parent.getElementsByTagName('label')[0].innerHTML += '<span style="color: red"> *</span>';
		}
	}

}
