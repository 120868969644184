import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'dataTappa'
})
export class DataTappaPipe implements PipeTransform {
	transform(value: any[], ...args: unknown[]): unknown {

		const obj = Object.keys(value)
			.sort()
			.reduce((accumulator: any, key: any) => {
				accumulator[key] = value[key];
				return accumulator;
			}, {});

		return Object.keys(obj).join(', ');
	}

}
