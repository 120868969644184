import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-btn-loader',
	templateUrl: './btn-loader.component.html',
	styleUrls: ['./btn-loader.component.scss']
})
export class BtnLoaderComponent implements OnInit {
	@Input() text: string;
	@Input() bool: boolean;

	constructor() {
		this.text = '';
		this.bool = false;
	}

	ngOnInit(): void {
	}

}
