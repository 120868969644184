import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}

	/**
	 * Ritorna al login ricaricando la pagina, in modo da eseguire la funzione di callback per il cambio di login ed
	 * eventualmente riprendere la navigazione senza doversi loggare nuovamente
	 */
	backToLogin(){
		window.open(window.location.protocol + '//' + window.location.host, 'self');
	}

}
