import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ICustomField, ICustomFieldArray, ICustomFieldBoolean, ICustomFieldDate, ICustomFieldFile, ICustomFieldNumber, ICustomFieldPhoto, ICustomFieldSelect, ICustomFieldString, ICustomFieldTime, IUser} from '../../interfaces';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
	selector: 'app-form-landing',
	templateUrl: './form-landing.component.html',
	styleUrls: ['./form-landing.component.scss']
})
export class FormLandingComponent implements OnInit {

	@Input() event_id: string;
	@Input() token_reg: string;
	@Input() user: IUser;

	userForm: FormGroup = new FormGroup({
		nome: new FormControl('', [Validators.required]),
		cognome: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required]),
	});

	customfieldArr: ICustomField[] | any[] = [];
	// customFieldUserCreate: { [name: string]: any; } = {};
	loading = false;

	constructor(private api: ApiService) {
	}

	ngOnInit(): void {
		this.api.customField.getCustomFields(this.event_id).then(res => {
			const resTmp = res as any[];
			this.customfieldArr = resTmp.filter(x => x.showInForm);
			this.loading = true;
			this.userForm.setValue({
				nome: this.user.nome,
				cognome: this.user.cognome,
				email: this.user.email,
			});
		}).catch(err => {
			console.error(err);
		});
	}

	fieldChangeAdd(data: { inputField: AbstractControl | string, customFieldData: ICustomFieldString | ICustomFieldNumber | ICustomFieldBoolean | ICustomFieldSelect | ICustomFieldArray | ICustomFieldDate | ICustomFieldTime | ICustomFieldPhoto | ICustomFieldFile }): void {
		if (typeof data.inputField === 'string') {
			this.user.custom_fields[data.customFieldData.field_name] = data.inputField;
		} else {
			this.user.custom_fields[data.customFieldData.field_name] = data.inputField.value;
		}
	}

	completeReg(): void {
		// const obj: Partial<IUser> = {
		// 	// email: this.user.email,
		// 	nome: this.userForm.get('nome')?.value,
		// 	cognome: this.userForm.get('cognome')?.value,
		// 	custom_fields: this.user.custom_fields
		// };

		let regComplete = true;
		for (const x of this.customfieldArr) {
			if (!(this.user.custom_fields.hasOwnProperty(x.field_name)) && x.required || this.user.custom_fields[x.field_name] === '') {
				regComplete = false;
				break;
			}
		}
	}

}
