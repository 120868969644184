import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'emptyField'
})
export class EmptyFieldPipe implements PipeTransform {

	transform(value: unknown): unknown {
		if (value === '' || value === undefined) {
			return '--';
		} else {
			return value;
		}
	}

}
