import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../services/notifications.service';
import {IUserAdmin} from '../../interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {ApiService} from '../../services/api.service';
import {AlertModalService} from '../../services/alert-modal.service';

@Component({
	selector: 'app-complete-reg',
	templateUrl: './complete-reg.component.html',
	styleUrls: ['./complete-reg.component.scss']
})
export class CompleteRegComponent implements OnInit {

	formReg: FormGroup = new FormGroup({
		nome: new FormControl('', [Validators.required]),
		cognome: new FormControl('', [Validators.required]),
		telefono: new FormControl('', [Validators.required]),
		email: new FormControl('', [Validators.required, Validators.email]),
		psw: new FormControl('', [Validators.required, Validators.pattern(/(?=^.{8,}$)(?=.*\d)(?=.*[-_!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)]),
		pswConf: new FormControl('', [Validators.required, Validators.pattern(/(?=^.{8,}$)(?=.*\d)(?=.*[-_!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)]),
	});

	user: Partial<IUserAdmin> = {};
	checkToken = true;
	tokenValid = false;
	alreadyVerified = false;
	token = '';
	uid = '';
	loadingButton = false;

	constructor(private alertService: AlertModalService, private route: ActivatedRoute, private notificationService: NotificationsService, private fns: AngularFireFunctions, private api: ApiService, private router: Router) {
	}

	ngOnInit(): void {
		this.checkToken = true;
		this.route.paramMap.subscribe(params => {
			try {
				const token = params.has('token') ? atob(params.get('token') as string) : null;
				const email = params.has('email') ? atob(params.get('email') as string) : null;
				if (token !== null && email !== null) {
					this.formReg.get('email')?.setValue(email);
					this.confirmToken(email, token);
				} else {
					this.notificationService.showErrorNotification('Dati di registrazione non validi, contatta l\'amministratore');
				}
			} catch (error) {
				this.checkToken = false;
				this.tokenValid = false;
				this.notificationService.showErrorNotification('Dati di registrazione non validi, contatta l\'amministratore');
			}
		});
	}

	/**
	 * Conferma se il token è valido
	 * @param _email indirizzo email associato alla richiesta
	 * @param _token token associato alla richiesta
	 */
	async confirmToken(_email: string, _token: string): Promise<void> {
		this.tokenValid = false;
		const callable = this.fns.httpsCallable('app/adminReg/adminCheckSignUpToken');
		await callable({email: _email, token_reg: _token}).toPromise().then(res => {
			this.uid = res.uid;
			this.token = _token;
			if (res.status === 200 && res.code === 'auth/ok') {
				// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.INFO, msg: 'Token reg valido'});
				this.tokenValid = true;
			} else if (res.status === 200 && res.code === 'auth/already_used') {
				// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.ERROR, msg: 'Token reg già utilizzato'});
				this.alreadyVerified = true;
			}
		}).catch(err => {
			// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.ERROR, msg: 'Errore nella richiesta della verifica del token reg'});
			console.error(err);
			this.tokenValid = false;
			this.notificationService.showErrorNotification('Dati di registrazione non validi, contatta l\'amministratore');
		}).finally(() => {
			this.checkToken = false;
		});
	}

	completeReg(): void {
		if (this.formReg.invalid) {
			this.notificationService.showErrorNotification('Controlla i campi inseriti');
			return;
		}

		if (this.formReg.get('psw')?.value !== this.formReg.get('pswConf')?.value) {
			this.notificationService.showErrorNotification('Le password non coincidono');
			return;
		}

		this.loadingButton = true;

		this.user = {
			uid: this.uid,
			password: this.formReg.get('psw')?.value,
			phoneNumber: this.formReg.get('telefono')?.value,
			customClaims: {
				nome: this.formReg.get('nome')?.value,
				cognome: this.formReg.get('cognome')?.value,
				token_reg: this.token
			}
		};

		this.api.adminUser.adminSignUpFinish(this.user).then(() => {
			// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.INFO, msg: 'Registrazione completata con successo.', email: this.user.email});
			this.alertService.openAlertModal('Attenzione', 'Registrazione completata con successo', true, (() => {
				this.router.navigate(['/login']);
			}));
		}).catch(err => {
			if (err.error.code === 'auth/phone-number-already-exists') {
				// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.ERROR, msg: 'Errore nella registrazione, telefono già utilizzato', email: this.user.email});
				this.notificationService.showErrorNotification('Il numero telefonico indicato è già assegnato ad un altro utente.');
				return;
			} else if (err.error.code === 'auth/invalid-phone-number') {
				if (err.error.message === 'TOO_LONG') {
					// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.ERROR, msg: 'Errore nella registrazione, telefono troppo lungo', email: this.user.email});
					this.notificationService.showErrorNotification('Il numero di telefono è troppo lungo.');
				} else {
					// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.ERROR, msg: 'Errore nella registrazione, telefono non valido', email: this.user.email});
					this.notificationService.showErrorNotification('Il numero di telefono non è valido.');
				}
			} else {
				// /*rimosso con replace all*/ this.log.writeLog{type: ETypeLog.ADMIN_REG, severity: ESeverityLog.ERROR, msg: 'Errore nella registrazione generico', email: this.user.email});
				this.notificationService.showErrorNotification('Errore durante la creazione dell\'utente, contattare l\'amministratore.');
			}
		}).finally(() => {
			this.loadingButton = false;
		});

	}

}
