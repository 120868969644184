import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {LoginComponent} from './components/login/login.component';
import {CompleteRegComponent} from './components/complete-reg/complete-reg.component';
import {map} from 'rxjs/operators';
import {AngularFireAuthGuard, customClaims} from '@angular/fire/compat/auth-guard';
import {pipe} from 'rxjs';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {EModuli} from './enum/EModuli';
import {SupportNotAuthorizedComponent} from './components/support-not-authorized/support-not-authorized.component';

// // Gestisco l'accesso alle pagine hostess - potranno accedere solo le hostess, moderatori e amministratori
// const hostessOnly = () => pipe(customClaims, map(claims => claims && checkMFA(claims) && claims.hasOwnProperty('ruolo') && claims.ruolo >= 80 && claims.ruolo <= 100 ? true : ['/login']));
//
// // Gestisco l'accesso alle pagine onsite - potranno accedere solo amministratori e utenti onsite
// const onSiteOnly = () => pipe(customClaims, map(claims => claims && checkMFA(claims) && claims.hasOwnProperty('ruolo') && claims.ruolo === 100 || claims.ruolo >= 1000 ? true : ['/login']));

// Gestisco l'accesso alla pagina qrCodeScanner - potranno accedere solo moderatori, amministratori o utenti con il modulo qrcodeScanner
const scannerAccess = () => pipe(customClaims, map(claims => (claims && checkMFA(claims) && claims.hasOwnProperty('moduli') && (claims.moduli as string[]).indexOf(EModuli.qrcodeScanner) > -1 || claims.ruolo >= 80) ? true : ['/login']));

// Gestisco l'accesso alla pagina qrCodeMasterclass - potranno accedere solo moderatori, amministratori o utenti con il modulo qrCodeMasterclass
const scannerMastercardAccess = () => pipe(customClaims, map(claims => (claims && checkMFA(claims) && claims.hasOwnProperty('moduli') && (claims.moduli as string[]).indexOf(EModuli.qrCodeMasterclass) > -1 || claims.ruolo >= 80) ? true : ['/login']));

// Gestisco l'accesso alla pagina support per mobile - potranno accedere solo gli utenti autorizzati
const webapp = () => pipe(customClaims, map(claims => {
        if (claims && checkMFA(claims) && claims.hasOwnProperty('moduli') && ((claims.moduli as string[]).indexOf(EModuli.webappSupport) > -1 || (claims.moduli as string[]).indexOf(EModuli.webappWhatsapp) > -1) || claims.ruolo >= 80) {
            return true;
        } else {
            return ['/login'];
        }
    }
));

const checkMFA = (claims: any) => {
    // console.log('adminOnly', claims);
    if (claims.hasOwnProperty('mfa_required')) {
        console.log('MFA required to access');
        return false;
    }
    return true;
};

// Accesso esclusivo per amministratori e moderatori
const adminOnly = () => pipe(customClaims, map((claims) => {
    if (claims && checkMFA(claims) && claims.hasOwnProperty('ruolo') && claims.ruolo >= 80) {
        return true;
    } else {
        return ['/login'];
    }
}));

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'page-not-found', component: PageNotFoundComponent},
    {path: 'support-not-authorized', component: SupportNotAuthorizedComponent},
    // {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
    {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AngularFireAuthGuard], data: {authGuardPipe: adminOnly}},
    // {path: 'hostess', loadChildren: () => import('./hostess/hostess.module').then(m => m.HostessModule), canActivate: [AngularFireAuthGuard], data: {authGuardPipe: hostessOnly}},
    {path: 'scan-qrcode', loadChildren: () => import('./scan-qrcode/scan-qrcode.module').then(m => m.ScanQrcodeModule), canActivate: [AngularFireAuthGuard], data: {authGuardPipe: scannerAccess}},
    {path: 'scan-qrcode-masterclass', loadChildren: () => import('./scan-qrcode-masterclass/scan-qrcode-masterclass.module').then(m => m.ScanQrcodeMasterclassModule), canActivate: [AngularFireAuthGuard], data: {authGuardPipe: scannerMastercardAccess}},
    {path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule), canActivate: [AngularFireAuthGuard], data: {authGuardPipe: webapp}},
    // {path: 'on_site', loadChildren: () => import('./onsite/onsite.module').then(m => m.OnsiteModule), canActivate: [AngularFireAuthGuard], data: {authGuardPipe: onSiteOnly}},
    {path: 'login', component: LoginComponent},
    {path: 'reset_password/:code', component: ResetPasswordComponent},
    {path: 'check-in/:event_id/:id_gruppo', loadChildren: () => import('./components/check-in/check-in.module').then(m => m.CheckInModule)},
    {path: 'complete-reg/:email/:token', component: CompleteRegComponent},
    {path: '**', redirectTo: '/page-not-found'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
