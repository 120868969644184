import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
	name: 'transformDate'
})
export class TransformDatePipe implements PipeTransform {

	constructor(private datePipe: DatePipe) {
	}

	transform(value: unknown): unknown {
		return this.datePipe.transform(new Date(value as string), `dd/MM/yyyy`);
	}
}
