import {Injectable, OnDestroy} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Subscription} from 'rxjs';
import firebase from 'firebase/compat/app';
import {GlobalService} from './global.service';
import {CustomError} from '../interfaces/CustomError';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    private isLoggedIn: boolean = false;
    private user: firebase.User | null = null;
    private token: any;
    private readonly authStateChangeSub: Subscription | undefined;
    private readonly tokenChangeResultSub: Subscription;

    private loginListeners: Map<string, Function> = new Map();
    private tokenListeners: Map<string, Function> = new Map();

    constructor(private afAuth: AngularFireAuth, private globalService: GlobalService) {
        if (this.afAuth) {
            this.tokenChangeResultSub = this.afAuth.idTokenResult.subscribe(resToken => {
                // console.log('tokenChangeResultSub', resToken);
                this.token = resToken;

                // Esegui i callback che si sono registrati
                for (const cb of this.tokenListeners.values()) {
                    cb();
                }

            });

            this.authStateChangeSub = this.afAuth.authState.subscribe(async (afUser) => {
                // Gestisce lo stato di login. L'utente sarà null se l'utente non è loggato.
                // console.log('authStateChangeSub', afUser);
                if (afUser) {
                    // console.log('User logged in');
                    this.user = afUser;

                    // await this.renewToken();
                    // localStorage.setItem('idToken', await afUser.getIdToken());

                    this.isLoggedIn = true;

                    // Esegui i callback che si sono registrati
                    for (const cb of this.loginListeners.values()) {
                        cb(true);
                    }
                } else {
                    // console.log('User logged out');
                    // localStorage.removeItem('idToken');
                    this.user = null;
                    this.token = null;
                    this.isLoggedIn = false;

                    // Esegui i callback che si sono registrati
                    for (const cb of this.loginListeners.values()) {
                        cb(false);
                    }
                }
            });
        } else {
            console.log('No afAuth found');
        }
    }

    async init(): Promise<void> {
    }

    /**
     * Avvia l'unsubscribe dell'auth state change.
     */
    ngOnDestroy(): void {
        console.log('ngOnDestroy AuthService');
        this.authStateChangeSub.unsubscribe();
        this.tokenChangeResultSub.unsubscribe();
    }

    /**
     * Aggiunge una funzione callback che verrà invocata quando cambia lo stato di login.
     *
     * @param {string} name - Il nome univoco per il listener.
     * @param {Function} cb - La funzione callback da invocare.
     */
    addLoginStatusChangeCb(name: string, cb: Function) {
        if (this.loginListeners.has(name)) {
            console.log('Listener già presente');
            return;
        }

        this.loginListeners.set(name, cb);
    }

    /**
     * Rimuove una funzione callback basata sul nome fornito.
     *
     * @param {string} name - Il nome del listener da rimuovere.
     */
    removeLoginStatusChangeCb(name: string) {
        if (!this.loginListeners.has(name)) {
            console.log('Listener non presente');
            return;
        }

        this.loginListeners.delete(name);
    }

    addTokenChangeCb(name: string, cb: Function): void {
        if (this.tokenListeners.has(name)) {
            console.log('Listener già presente');
            return;
        }

        this.tokenListeners.set(name, cb);
    }


    /**
     * Rimuove una funzione callback basata sul nome fornito.
     *
     * @param {string} name - Il nome del listener da rimuovere.
     */
    removeTokenChangeCb(name: string) {
        if (!this.tokenListeners.has(name)) {
            console.log('Listener non presente');
            return;
        }

        this.tokenListeners.delete(name);
    }

    /**
     * Restituisce true o false se l'utente è loggato
     */
    checkUserLoggedin(): boolean {
        return this.isLoggedIn;
    }

    /**
     * Restituisce il ruolo dell'utente oppure FALSE.
     */
    getRoleUser(): number {
        // console.log(this.user);
        // console.log(this.token);
        if (this.user && this.token) {
            if (typeof this.token.claims.ruolo === 'number') {
                return this.token.claims.ruolo;
            } else if (typeof this.token.claims.ruolo === 'string') {
                return parseInt(this.token.claims.ruolo);
            }
        }

        console.log('User does NOT exist');
        return -1;
    }

    /**
     * Ottengo i moduli salvati nei claims dell'utente google
     */
    async getModuliUser(): Promise<any> {
        if (this.user && this.token) {
            if (this.token.claims.hasOwnProperty('moduli') && Array.isArray(this.token.claims.moduli)) {
                return this.token.claims.moduli;
            }
        }
        return [];
    }

    /**
     * Restituisce il token già letto
     */
    getIdToken(): firebase.auth.IdTokenResult {
        return this.token;
    }

    /**
     * Restituisce l'utente loggato oppure null
     */
    getUser(): firebase.User | null {
        if (this.user) {
            return this.user;
            // return {
            //     displayName: this.user.displayName || null,
            //     email: this.user.email || null,
            //     phoneNumber: this.user.phoneNumber || null,
            //     uid: this.user.uid || null
            // };
        } else {
            return null;
        }
    }

    async login(email: string, password: string): Promise<any> {
        try {
            // Tentativo di login
            await this.afAuth.signInWithEmailAndPassword(email, password);
            // const res = await this.afAuth.signInWithEmailAndPassword(email, password);
            // if (res && res.hasOwnProperty('operationType') && res.operationType === 'signIn' && res.hasOwnProperty('user') && res.user) {
            //     console.log('Signing success, checking MFA', res);
            //     const token = await res.user.getIdTokenResult();
            //     // console.log(token);
            //     if (token.claims.hasOwnProperty('mfa_required') && token.claims.mfa_required === true) {
            //         console.log('MFA required');
            //         throw new CustomError('auth/mfa', 'MFA required');
            //     } else {
            //         console.log('MFA not required');
            //     }
            // } else {
            //     console.log('else', res);
            //     throw new CustomError('auth/error', 'Errore durante il tentativo di accesso');
            // }
            // {
            //     "returnSecureToken": true,
            //     "email": "gianluca.giacalone@giacapp.com",
            //     "password": "giacaGiaca24?"
            // }
        } catch (error) {
            // Gestione errore di login
            console.warn('catch1', error);
            if (error.message) {
                if (error.message.indexOf('password-update-required') !== -1 || error.message.indexOf('permission-update-required') !== -1) {
                    throw new CustomError('auth/password-update-required', error.message);
                } else if (error.message.indexOf('password-expired') !== -1) {
                    throw new CustomError('auth/password-expired', error.message);
                } else if (error.message.indexOf('auth/too-many-requests') !== -1) {
                    throw new CustomError('auth/too-many-requests', error.message);
                } else if (error.message === 'INVALID_PASSWORD') {
                    throw new CustomError(error.code, 'Password non valida durante il login');
                }
            }
            throw new CustomError(error.code, error.message);
        }
    }

    logOut(): any {
        this.afAuth.signOut().then(() => {
            this.globalService.logoutCallBackExec();
            window.location.href = '/';
            // this.userStateChange = null;
            // this.router.navigate(['/login']);
        });
    }

    logOutSupport(): any {
        this.afAuth.signOut().then(() => {
            this.globalService.logoutCallBackExec();
            window.location.href = '/support';
        });
    }
}
