import {REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';
import {environment} from '../environments/environment';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {TitleCasePipe} from '@angular/common';
import {PlatformModule} from '@angular/cdk/platform';
import {AppRoutingModule} from './app-routing.module';
import {MDB_DATE_OPTIONS, MDBSpinningPreloader, MdbTimePickerModule, PreloadersModule, ToastModule} from 'ng-uikit-pro-standard';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireStorageModule, BUCKET} from '@angular/fire/compat/storage';
import {CoreModule} from './core/core.module';
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {LoginComponent} from './components/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CompleteRegComponent} from './components/complete-reg/complete-reg.component';
import {CheckInComponent} from './components/check-in/check-in.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';

const optionDate = {
	dayLabels: {su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mer', th: 'Gio', fr: 'Ven', sa: 'Sab'},
	dayLabelsFull: {
		su: 'Domenica', mo: 'Lunedì', tu: 'Martedì', we: 'Mercoledì', th: 'Giovedì', fr: 'Venerdì', sa: 'Sabato'
	},
	monthLabels: {
		1: 'Gen', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'Mag', 6: 'Giu', 7: 'Lug', 8: 'Ago', 9: 'Set', 10: 'Ott', 11: 'Nov', 12: 'Dic'
	},
	monthLabelsFull: {
		1: 'Gennaio', 2: 'Febbraio', 3: 'Marzo', 4: 'Aprile', 5: 'Maggio', 6: 'Giugno', 7: 'Luglio', 8: 'Agosto', 9: 'Settembre', 10: 'Ottobre', 11: 'Novembre', 12: 'Dicembre'
	},
	todayBtnTxt: 'Oggi',
	clearBtnTxt: 'Pulisci',
	closeBtnTxt: 'Chiudi',
	showTodayBtn: true,
	showClearDateBtn: true,
	firstDayOfWeek: 'mo',
	minYear: 1900
};

@NgModule({
	imports: [
		BrowserModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireStorageModule,
		BrowserAnimationsModule,
		PreloadersModule,
		ToastModule.forRoot(),
		CoreModule,
		AppRoutingModule,
		PlatformModule,
        MdbTimePickerModule
    ],
	declarations: [
		AppComponent,
		PageNotFoundComponent,
		LoginComponent,
		CompleteRegComponent,
		CheckInComponent,
		ResetPasswordComponent
	],
	exports: [],
	// entryComponents: [AlertModalComponent, LoadingSpinnerComponent],
	providers: [
		MDBSpinningPreloader,
		TitleCasePipe,
		{provide: MDB_DATE_OPTIONS, useValue: optionDate},
		{provide: REGION, useValue: 'europe-west3'},
		{provide: BUCKET, useValue: environment.storage},
		// {provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined},
		// {provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined},
		{provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
