import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../services/notifications.service';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ModalDirective} from 'ng-uikit-pro-standard';
import {AlertModalService} from '../../services/alert-modal.service';
import {GlobalService} from '../../services/global.service';
import {EModuli} from '../../enum/EModuli';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('modalCodeLogin') modalCodeLogin: ModalDirective;

    loginForm: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
        isEmailRemember: new FormControl(false),
    });

    verifyCodeForm: FormGroup = new FormGroup({
        code: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(6), Validators.maxLength(6)])
    });

    isLoggingIn = false;
    loginLoad = false;
    verifyCodeLoading = false;
    sendNewCode = 0;
    access_type = '';

    errorMessages: Record<string, string> = {
        'auth/password-expired': 'La tua password è scaduta, ti è stata inviata un\'email per impostarne un\'altra',
        'auth/password-update-required': 'Per motivi di sicurezza la password deve essere reimpostata a causa di un aggiornamento del sistema.',
        'auth/send-mfa-error': 'Errore durante l\'invio dell\'MFA',
        'auth/save-mfa-error': 'Errore durante il salvataggio dell\'MFA',
        'auth/permission-update-required': 'Per motivi di sicurezza la password deve essere reimpostata a causa di un aggiornamento del sistema.',
        'auth/wrong-password': 'Password errata',
        'auth/network-request-failed': 'Impossibile stabilire un collegamento con il server.',
        'auth/invalid-login-credentials': 'Utente o password errata',
        'auth/too-many-requests': 'Troppe richieste, account temporaneamente bloccato. Puoi accedere immediatamente sostituendo la password oppure riprova più tardi.',
        'code/profile_expired': 'Il profilo non è più abilitato. Data di scadenza superata.',
        'auth/mfa': 'Errore di autenticazione a due fattori (MFA)',
        'code/invalid': 'Token non valido',
        'code/error-on-post-login': 'Errore durante il login successivo alla verifica del token',
        'code/invalid-fields': 'Campi inseriti non validi',
        'code/insert-code': 'Inserisci il codice inviato tramite messaggio',
        'code/invalid-code': 'Formato del codice non valido',
        'Error: Inserisci la tua email': 'Inserisci la tua email'
    };

    constructor(private alertService: AlertModalService, private auth: AuthService, private notification: NotificationsService, private api: ApiService, private router: Router, private globalService: GlobalService, private afAuth: AngularFireAuth) {
    }

    ngOnInit(): void {
        this.checkEmailRemember();
        this.auth.addLoginStatusChangeCb('loginComponent', this.tokenChangeCb.bind(this));
        this.verifyCodeForm.get('code').valueChanges.subscribe(value => {
            if (value && value.length > 0) {
                this.verifyCodeForm.get('code').setValue(value.replace(/[^0-9]/g, ''), {emitEvent: false});
            }
        });
    }

    ngOnDestroy(): void {
        this.auth.removeLoginStatusChangeCb('loginComponent');
        this.auth.removeTokenChangeCb('loginComponent');
    }

    /**
     * Gestisco il cambio del token al login ed al logout
     */
    async tokenChangeCb(): Promise<void> {
        // console.log('Token changed callback');
        const token = this.auth.getIdToken();
        // console.log(token);
        if (token) {
            if (token.claims.hasOwnProperty('mfa_required')) {
                if (this.isLoggingIn) {
                    console.log('Richiesto MFA');
                    this.access_type = token.claims.access_type;
                    if (token.claims.access_type === 'none') {
                        console.log('No MFA required');
                        this.postLoginRedirects();
                    } else if (token.claims.access_type === 'email') {
                        console.log('MFA required: EMAIL');
                        this.modalCodeLogin.show();
                    } else if (token.claims.access_type === 'phone') {
                        console.log('MFA required: SMS');
                        this.modalCodeLogin.show();
                    }
                }
            } else {
                console.log('MFA già inserito o non richiesto');
                this.postLoginRedirects();
            }
        } else {
            console.log('No ID token found');
        }
    }

    /**
     * Se è presente la richiesta di salvataggio dell'email la ripristina nel form
     */
    checkEmailRemember(): void {
        const local = localStorage.getItem('remember_email');
        if (local) {
            this.loginForm.get('email')?.setValue(local);
            this.loginForm.get('isEmailRemember').setValue(local);
        }
    }

    async login(): Promise<void> {
        try {
            if (this.loginForm.invalid) {
                throw new Error('code/invalid-fields');
            }

            this.loginLoad = true;
            this.loginForm.disable();
            const user = {
                email: this.loginForm.get('email')?.value,
                password: this.loginForm.get('password')?.value,
            };

            // Gestione dell'opzione per ricordare l'email all'apertura del componente login
            if (this.loginForm.get('isEmailRemember').value) {
                localStorage.setItem('remember_email', user.email);
            } else {
                localStorage.removeItem('remember_email');
            }

            // Avvio la funzione di login passandogli email e password
            this.auth.addTokenChangeCb('loginComponent', this.tokenChangeCb.bind(this));
            this.isLoggingIn = true;
            await this.auth.login(user.email, user.password);
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loginLoad = false;
            this.loginForm.enable();
        }
    }

    async passwordLost(): Promise<void> {
        try {
            if (this.loginForm.get('email')?.invalid) {
                throw new Error('Inserisci la tua email');
            }

            this.alertService.showLoading(`Invio dell'email in corso...`);
            this.loginForm.disable();

            await this.api.auth.resetPassword(this.loginForm.get('email')?.value);

            this.loginForm.enable();
            this.alertService.closeLoading();
            this.notification.showSuccessNotification('Email di ripristino password inviata.');
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loginForm.enable();
            this.alertService.closeLoading();
        }
    }

    async verifyCode(): Promise<void> {
        try {
            const code = this.verifyCodeForm.get('code').value;
            // console.log(code, code.length);
            // for(const c of code) {
            //     console.log(c, parseInt(c));
            // }
            if (code.length === 0) {
                throw new Error('code/insert-code');
            }

            if (this.verifyCodeForm.invalid) {
                throw new Error('code/invalid-code');
            }

            this.verifyCodeLoading = true;
            this.verifyCodeForm.disable();

            let email = this.loginForm.get('email').value;
            const token = this.auth.getIdToken();
            if (token && token.claims && token.claims.email) {
                email = token.claims.email;
            }
            const resVerify = await this.api.auth.checkLoginCode(email, code);

            if (resVerify.code !== 'code/ok') {
                throw new Error(resVerify.code);
            }

            // Procedo con il login utilizzando il token generato dopo la verifica del codice.
            const res = await this.afAuth.signInWithCustomToken(resVerify.token);

            if (!res) {
                throw new Error('code/error-on-post-login');
            }

            this.postLoginRedirects();
        } catch (error) {
            this.handleError(error);
            this.verifyCodeLoading = false;
            this.verifyCodeForm.enable();
        }
    }

    async resendCode(): Promise<void> {
        if (this.verifyCodeLoading) {
            return;
        }
        try {
            this.sendNewCode = 1;
            const token = this.auth.getIdToken();
            let email = this.loginForm.get('email').value;
            if (token && token.claims && token.claims.email) {
                email = token.claims.email;
            }

            const resSend = await this.api.auth.resendLoginCode(email);

            if (resSend.code === 'code/ok') {
                this.sendNewCode = 2;
            } else {
                this.sendNewCode = 3;
            }
            setTimeout(() => {
                this.sendNewCode = 0;
            }, 2000);
        } catch (error) {
            this.handleError(error);
        }
    }

    async postLoginRedirects(): Promise<void> {
        const token = this.auth.getIdToken();
        if (!token) {
            console.log('ID token non trovato');
            return;
        }
        const ruolo = token.claims.ruolo;
        const moduli: string[] = token.claims.hasOwnProperty('moduli') ? token.claims.moduli : [];

        if (ruolo === 2000) {
            console.log('on_site');
            this.router.navigate(['/on_site']);
        } else if (ruolo === 80) {
            console.log('hostess');
            this.router.navigate(['/hostess']);
        } else if (ruolo >= 1000) {
            console.log('on_site');
            this.router.navigate(['/on_site']);
        } else {
            if (this.globalService.initialPath !== '' && this.globalService.initialPath !== '/' && this.globalService.initialPath !== '/login') {
                if (this.globalService.initialPath === '/scan-qrcode' && !moduli.includes(EModuli.qrcodeScanner)) {
                    this.router.navigate(['/admin/eventi']);
                } else if (this.globalService.initialPath === '/scan-qrcode-masterclass' && !moduli.includes(EModuli.qrCodeMasterclass)) {
                    this.router.navigate(['/admin/eventi']);
                } else if (this.globalService.initialPath === '/support' && !moduli.includes(EModuli.webappSupport) && !moduli.includes(EModuli.webappWhatsapp)) {
                    this.router.navigate(['/support-not-authorized']);
                } else {
                    this.router.navigate([this.globalService.initialPath]);
                }
            } else {
                this.router.navigate(['/admin/eventi']);
            }
        }
    }

    closeVerifyCode(): void {
        this.modalCodeLogin.hide();
        this.loginLoad = false;
        this.verifyCodeLoading = false;
        this.loginForm.enable();
        this.verifyCodeForm.reset();
        this.auth.removeTokenChangeCb('loginComponent');
    }

    private handleError(error: any): void {
        console.log(error);
        let errorMessage = '';
        let errorExtString = null;

        // Regex che cerca una struttura caratteri validi per prefisso e codice all'interno di ( )
        const regex = /([a-zA-Z0-9_]+\/[a-zA-Z0-9_-]+)/;
        const errorExt = error.message.match(regex);
        if (errorExt && Array.isArray(errorExt) && errorExt.length > 1) {
            errorExtString = errorExt[1];
            console.error('Errore rilevato', errorExtString);
            for (const key in this.errorMessages) {
                console.log(`Key: ${key} - Message: ${this.errorMessages[key]}`);
                if (errorExtString.indexOf(key) > -1) {
                    console.log(`Messaggio di errore : ${errorMessage}`);
                    errorMessage = this.errorMessages[key];
                    break;
                }
            }
        }

        if (errorMessage === '') {
            if (this.errorMessages.hasOwnProperty(error)) {
                errorMessage = this.errorMessages[error];
            } else {
                errorMessage = 'Errore sconosciuto, verificare in console';
            }
        }

        this.notification.showErrorNotification(errorMessage);
    }
}
