import {Injectable} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {IApiEventGetModeratoriResponse, IEvento, IGruppo, IUser} from '../interfaces';
import {EModuli} from '../enum/EModuli';
import {IModulo} from '../interfaces/IModulo';
import {IMasterclass} from '../interfaces/IMasterclass';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	public eventList: IEvento[] = [];
	public evento: IEvento | null = null;
	public masterclasses: IMasterclass[] | null = null;
	public userModeratori: IApiEventGetModeratoriResponse[] | null = null;
	public gruppiEvento: IGruppo[] = [];
	public utentiEvento: IUser[] = [];
	public notificheSupport: any[] = [];
	public notificheWhatsappSupport: any[] = [];

	public moduliOptions = [
		{value: EModuli.tappe, label: 'Tappe'},
		{value: EModuli.program, label: 'Program'},
		{value: EModuli.qrcode, label: 'QR-Code'},
		{value: EModuli.masterclass, label: 'Masterclass'},
		{value: EModuli.contattiUtili, label: 'Contatti Utili'},
		{value: EModuli.mappe, label: 'Mappe'},
		{value: EModuli.sms, label: 'SMS'},
		{value: EModuli.notifications, label: 'Notifiche'},
		{value: EModuli.appSupport, label: 'Supporto App'},
		{value: EModuli.whatsappSupport, label: 'Supporto Whatsapp'},
		{value: EModuli.qrcodeScanner, label: 'Scanner QR-Codes'},
		{value: EModuli.qrCodeMasterclass, label: 'Scanner QR-Codes Masterclass'},
		{value: EModuli.webappSupport, label: 'Support Mobile'},
		{value: EModuli.webappWhatsapp, label: 'Whatsapp Support Mobile'},
		{value: EModuli.premi, label: 'Gestione premi'},
	];
	public initialPath = '';
	public moduli: IModulo[] = [];
	private logoutCallBack;

	constructor(private storage: AngularFireStorage) {
	}

	/**
	 * Genero un colore random
	 */
	getRandomColor(): string {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * letters.length)];
		}
		return color;
	}

	/**
	 * Genero una stringa alfa numerica random
	 * @param len lunghezza della string, default 10
	 */
	randomStr(len: number = 10): string {
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const result = [];
		const charactersLength = characters.length;
		for (let i = 0; i < len; i++) {
			result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
		}
		return result.join('');
	}

	/**
	 * Restituisce un random compreso tra DUE valori
	 * @param start Numero iniziale
	 * @param end Numero finale
	 */
	randomBetween(start: number, end: number): number {
		return Math.floor(Math.random() * end) + start;
	}

	downloadImageFromPath(path: string): Promise<string> {
		return new Promise(resolve => {
			return this.storage.ref(path).getDownloadURL().subscribe(res => {
				resolve(res);
			});
		});
	}

	errorCheckDuplicateFields(err: any): string {
		let text = '';
		if (err.code === 'code/already_used') {
			text = 'Ci sono dei campi gia utilizzati:<br><br>';
			if (err.usedFields.groups.length !== 0) {
				err.usedFields.groups.forEach((x: any) => {
					text += `Gruppo: <b>${x.id_gruppo}</b>, Field: <b>${x.field}</b><br>`;
				});
			}

			if (err.usedFields.hostess.length !== 0) {
				text += 'Hostess contiene i field: ';
				err.usedFields.hostess.forEach((x: any) => {
					text += `<b>${x}, </b>`;
				});
				text = text.substring(0, text.length - 6) + '</b>';
				text += '<br>';
			}

			if (err.usedFields.users.length !== 0) {
				text += 'Users contiene i field: ';
				err.usedFields.users.forEach((x: any) => {
					text += `<b>${x}, </b>`;
				});
				text = text.substring(0, text.length - 6) + '</b>';
				text += '<br>';
			}

		}
		return text;
	}

	/**
	 * Funzione per ridimensionare le immagini da caricare secondo parametri richiesti
	 *
	 * @param maxWidth nuova larghezza
	 * @param maxHeight nuova altezza
	 *
	 * @param crop Converte eseguendo un crop centrale alla dimensione richiesta
	 * @param file File da processare, può essere in formato string, File o Blob
	 */
	async resizeImage(maxWidth: number, maxHeight: number, crop: boolean, file: Blob): Promise<Blob> {
		// TODO Implementare il mantenimento dell'aspect ratio utilizzando la variabile maintainRatio
		return new Promise((result, reject) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				const image = new Image();
				image.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');

					if (ctx === null) {
						console.error('Impossibile creare il canvas per la conversione');
						reject(false);
						return;
					}

					let sourceX = 0;
					let sourceY = 0;

					// Calcolo il moltiplicatore per ridimensionare l'immagine
					const factor = Math.max(maxWidth / image.width, maxHeight / image.height);
					const destWidth = image.width * factor;
					const destHeight = image.height * factor;

					if (crop) {
						canvas.width = maxWidth;
						canvas.height = maxHeight;

						// Calcolo l'offset X o Y utilizzando la differenza tra le 2 immagini / 2
						if (destWidth > maxWidth) {
							sourceX = (destWidth - maxWidth) / 2;
						}
						if (destHeight > maxHeight) {
							sourceY = (destHeight - maxHeight) / 2;
						}
					} else {
						canvas.width = destWidth;
						canvas.height = destHeight;
					}

					ctx.drawImage(image, sourceX, sourceY, image.width, image.height, 0, 0, destWidth, destHeight);
					canvas.toBlob((blob) => {
						if (blob) {
							result(blob);
						} else {
							console.error('Impossibile creare il blob per la conversione dell\'immagine');
							reject(false);
						}
					}, 'image/jpeg', 0.90);
				};
				if (image && event.target && event.target.result) {
					image.src = event.target.result as string;
				}
			};
			reader.readAsDataURL(file);
		});
	}

	/**
	 * Verifica estensione e dimensione in Kb di un file
	 *
	 * @param file File da verificare
	 * @param maxFileSize Dimensione massima del file es: 1024
	 */
	testFile(file: File, maxFileSize: number): boolean {
		if (!(file.name.endsWith('.jpg') || file.name.endsWith('.png'))) {
			return false;
		}

		return file.size / 1024 <= maxFileSize;
	}

	/**
	 * Verifica l'aspect ratio della risoluzione di un'immagine.
	 * Il calcolo è fatto tenendo conto del lato più lungo.
	 *
	 * Es:
	 * w1000xh500 = 2.0
	 * w1920xh1080 = 1.777
	 * w500xh1000 = 2 (aspect invertito altrimenti 0.5)
	 *
	 * @param blob Blob dell'immagine
	 * @param imageRatio Ratio richiesta es: 1.0
	 * @param force Forza la rotazione del calcolo del ratio, es w500xh1000 verrà calcolato w*h, quindi ratio 0.5, altrimenti il calcolo verrà ruotato h*e, quindi il ratio sarà comunque 2.0
	 */
	testRatio(blob: any, imageRatio: number, force = false): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const imgTmp = new Image();
			imgTmp.onload = async () => {
				let imgTmpRatio: number;

				// Verifico come calcolare il valore ratio
				if (imgTmp.width >= imgTmp.height || force) {
					imgTmpRatio = Math.round(((imgTmp.width / imgTmp.height) + Number.EPSILON) * 100) / 100;
				} else {
					imgTmpRatio = Math.round(((imgTmp.height / imgTmp.width) + Number.EPSILON) * 100) / 100;
				}

				if (imgTmpRatio !== imageRatio) {
					resolve(false);
				} else {
					resolve(true);
				}
			};
			imgTmp.src = blob;
		});
	}

	/**
	 * Gestisce il caricamento di un file testando tipo, peso e dimensione.
	 *
	 * @param file File da gestire
	 * @param maxSize Dimensione massima richiesta
	 * @param ratio Ratio richiesto, se -1 non esegue il controllo
	 * @param force Forza il controllo dell'aspect ratio utilizzando w*h, altrimenti utilizza il lato più lungo nel calcolo
	 * @return Promise<number|string> Restituisce 0,1,2 oppure una stringa con il blob del file da caricare
	 */
	async insertFile(file: File, maxSize, ratio: -1 | number, force: boolean = false): Promise<0 | 1 | 2 | string> {
		return new Promise(async (resolve) => {
			if (!file) {
				return resolve(0);
			}

			if (!this.testFile(file, maxSize)) {
				return resolve(1);
			}

			const reader = new FileReader();
			reader.onload = async (evt: any) => {
				const res = await this.testRatio(evt.target.result, ratio, force);
				if (!res && ratio !== -1) {
					return resolve(2);
				} else {
					return resolve(evt.target.result);
				}
			};
			reader.onerror = (evt: any) => {
				console.error('Impossibile leggere il file', evt);
				return resolve(0);
			};
			reader.readAsDataURL(file);
		});
	}


	/**
	 * Ordina un array di utenti, raggruppando gli ospiti con i loro accompagnatori.
	 *
	 * Ogni ospite principale (identificato dall'avere un campo 'guest_uid') viene seguito nel risultato
	 * dal suo accompagnatore (identificato dall'avere un campo 'main_data' con 'uid' uguale all'id dell'ospite).
	 * Gli utenti che non sono né ospiti principali né accompagnatori vengono semplicemente inclusi nell'array risultante.
	 *
	 * @param {IUser[]} arr - L'array di utenti da ordinare.
	 * @returns {IUser[]} L'array di utenti ordinato, con ogni ospite seguito dal suo accompagnatore.
	 */
	ordinaOspitiAccompagnatore(arr: IUser[]): IUser[] {
		// Mappa per la ricerca veloce degli utenti per ID
		const idToUserMap: Map<string, IUser> = new Map(arr.map(user => [user.id, user]));

		// Mappa per memorizzare l'array ordinato
		const sortedMap: Map<string, IUser> = new Map();

		// Itera su tutti gli utenti nell'array di input
		for (const user of arr) {
			// Se l'utente esiste già nella mappa ordinata o ha un campo main_data, passa al prossimo utente
			if (sortedMap.has(user.id) || user.hasOwnProperty('main_data')) continue;

			// Aggiunge l'utente alla mappa ordinata
			sortedMap.set(user.id, user);

			// Se l'utente ha un accompagnatore (guest_uid), lo cerca e lo aggiunge alla mappa ordinata
			if (user.hasOwnProperty('guest_uid') && user.guest_uid !== '') {
				const accompagnatore: IUser = idToUserMap.get(user.guest_uid);
				// Se l'accompagnatore è valido e il campo main_data.uid è uguale all'id dell'utente lo aggiungo alla variabile sortedMap
				if (accompagnatore && accompagnatore.hasOwnProperty('main_data') && accompagnatore.main_data.uid === user.id) {
					sortedMap.set(user.guest_uid, accompagnatore);
				}
			}
		}

		if(arr.length !== sortedMap.size){
			console.warn(`Trovati ${Math.abs(arr.length - sortedMap.size)} mismatch accompagnatori e ospiti`);
		}

		return Array.from(sortedMap.values());
	}

	addOnLogoutCallBack(cb: any) {
		this.logoutCallBack = cb;
	}

	logoutCallBackExec() {
		if (this.logoutCallBack) {
			this.logoutCallBack();
		}
	}

    /**
     * Genera un hash per un oggetto o valore ordinando le chiavi.
     *
     * @example
     * const obj = {nome: 'Gianni', uid: '5', gruppi: [{nome: 'G1', id: '101'}, {nome: 'G3', id: '103'}], params: {color: 'yellow', sound: true}, prefNum: 22}
     * Esempio di hash restituito: 'gruppi:id:101|nome:G1,id:103|nome:G3|nome:Gianni|params:color:yellow|sound:true|prefNum:22|uid:5'
     *
     * @private
     * @param {*} val - Il valore per cui generare l'hash.
     * @returns {string} - L'hash generato.
     */
    sortedHash(val: any): string{
        // Gestisco gli array
        if (Array.isArray(val)) {
            // Ordina l'array basato sugli hash dei suoi elementi e unisce i risultati utilizzando la virgola
            return val.map(x => this.sortedHash(x)).sort().join(',');
        }

        // Gestisco gli oggetti
        if (typeof val === "object" && val !== null) {
            const keys = Object.keys(val).sort(); // Ordina le chiavi per garantire la coerenza e unisce i risultati utilizzando il pipe
            return keys.map(key => `${key}:${this.sortedHash(val[key])}`).join("|");
        }

        // Altrimenti, restituisci il valore convertito in stringa
        return String(val);
    }

    /**
     * Verifica se due array di oggetti sono uguali, indipendentemente dall'ordine.
     *
     * @param {Array} a - Primo array da confrontare.
     * @param {Array} b - Secondo array da confrontare.
     * @returns {boolean} - Ritorna `true` se gli array sono uguali, altrimenti `false`.
     */
    isSameArrayIgnoreOrder(a: Array<any>, b: Array<any>): boolean {
        // Verifica il tipo delle due variabili e la lunghezza degli array, se diversa esce con false
        if ((typeof a !== typeof b) || (Array.isArray(a) && Array.isArray(b) && a.length !== b.length)) {
            return false;
        }

        const mapA = new Map();
        const mapB = new Map();

        // Popola le mappe con gli hash degli oggetti
        for (const obj of a) {
            const objHash = this.sortedHash(obj);
            mapA.set(objHash, (mapA.get(objHash) || 0) + 1);
        }

        for (const obj of b) {
            const objHash = this.sortedHash(obj);
            mapB.set(objHash, (mapB.get(objHash) || 0) + 1);
        }

        // Confronta le mappe
        for (const [key, value] of mapA) {
            if (mapB.get(key) !== value) return false;
        }

        for (const [key, value] of mapB) {
            if (mapA.get(key) !== value) return false;
        }

        // console.log('Array uguali');
        // console.log(mapA, mapB);

        return true;
    }

}
