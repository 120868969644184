import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {IEvento, IGruppo} from '../../interfaces';

@Component({
	selector: 'app-check-in',
	templateUrl: './check-in.component.html',
	styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent implements OnInit {

	event_id: string = '';
	id_gruppo: string = '';

	evento: IEvento;
	gruppo: IGruppo;

	users: any[];

	isLoaded = false;

	field: { value: string, label: string, type: string }[] = [
		{value: 'nome', label: 'Nome', type: 'string'},
		{value: 'cognome', label: 'Cognome', type: 'string'},
		{value: 'email', label: 'Email', type: 'string'},
		{value: 'data_partecipazione', label: 'Data partecipazione', type: 'date'},
	];

	colSelected: { label: string, value: string, type: string, index: number }[] = [{
		label: 'Nome',
		value: 'nome',
		index: 0,
		type: 'string'
	}, {label: 'Cognome', value: 'cognome', index: 1, type: 'string'}, {
		label: 'Email',
		value: 'email',
		index: 2,
		type: 'string'
	}, {label: 'Data Partecipazione', value: 'data_partecipazione', index: 3, type: 'data'}];

	constructor(private api: ApiService, private route: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.route.params.subscribe(res => {
			this.event_id = res.event_id;
			this.id_gruppo = res.id_gruppo;
			this.loadUsers();
			this.api.customField.getCustomFields(this.event_id).then(cfs => {
				const cfsTmp = cfs as any[];
				cfsTmp.forEach(x => {
					this.field.push({label: x.name, value: x.field_name, type: x.type});
				});
			});
		});
	}

	loadEvent(): void {
		this.api.event.getEvento(this.event_id).then(res => {
			this.evento = res;
			this.loadGruppo();
		});
	}

	loadGruppo(): void {
		this.api.gruppi.getGruppo(this.event_id, this.id_gruppo).then(res => {
			this.gruppo = res;
			this.isLoaded = true;
		}).catch(() => {
			// console.error(err);
		});
	}

	loadUsers(): void {
		this.api.eventoUsers.getUsers(this.event_id, this.id_gruppo).then(res => {
			this.users = res;
			this.loadEvent();
		});
	}

	changeCheckCol(e: any, _label: string, _value: string, _index: number, _type: string): void {
		if (e.checked) {
			this.colSelected.push({label: _label, value: _value, index: _index, type: _type});
		} else {
			const i = this.colSelected.findIndex(x => x.value === _value);
			this.colSelected.splice(i, 1);
		}
		this.colSelected.sort((a, b) => a.index - b.index);

	}

}
