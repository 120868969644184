import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
	ICustomFieldArray,
	ICustomFieldBoolean,
	ICustomFieldDate,
	ICustomFieldFile,
	ICustomFieldNumber,
	ICustomFieldPhoto,
	ICustomFieldSelect,
	ICustomFieldString,
	ICustomFieldTime
} from '../../interfaces';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/compat/storage';
import {finalize, tap} from 'rxjs/operators';
import {ApiService} from '../../services/api.service';
import {AlertModalService} from '../../services/alert-modal.service';
import {GlobalService} from '../../services/global.service';
import {IOption} from 'ng-uikit-pro-standard';

@Component({
	selector: 'app-custom-field-input',
	templateUrl: './custom-field-input.component.html',
	styleUrls: ['./custom-field-input.component.scss']
})
export class CustomFieldInputComponent implements OnInit {

	formCustomField: FormGroup;

	@Input() customFieldData: ICustomFieldString | ICustomFieldNumber | ICustomFieldBoolean | ICustomFieldSelect | ICustomFieldArray | ICustomFieldDate | ICustomFieldTime | ICustomFieldPhoto | ICustomFieldFile;
	@Input() valueSelected: any;
	@Input() isLanding: boolean = false;
	@Input() isEditMode: boolean = false;
	@Input() event_id: string;
	@Input() uid: string;

	selectItem: IOption[] = [];
	file: any;
	labelUpload = '';
	imageUrl = '';
	selectedOption = {label: '', value: '', selected: true};

	@Output() fieldForm: EventEmitter<{
		inputField: AbstractControl | string | any,
		customFieldData: ICustomFieldString | ICustomFieldNumber | ICustomFieldBoolean | ICustomFieldSelect | ICustomFieldArray | ICustomFieldDate | ICustomFieldTime | ICustomFieldPhoto | ICustomFieldFile
	}> = new EventEmitter();

	constructor(private storage: AngularFireStorage, private api: ApiService, private alertService: AlertModalService, private globalSrv: GlobalService) {
	}

	async ngOnInit() {
		const validator = [];
		if (this.customFieldData.required) {
			validator.push(Validators.required);
		}

		this.formCustomField = new FormGroup({
			customField: new FormControl({value: null, disabled: !this.isEditMode}, validator)
		});

		if(!this.customFieldData.userModify){
			this.formCustomField.disable();
		}

		if(this.valueSelected && typeof this.valueSelected !== 'object'){
			this.valueSelected = this.valueSelected.toString();
		} else if(this.valueSelected && this.valueSelected.hasOwnProperty('_seconds')){
			this.valueSelected = new Date(this.valueSelected._seconds * 1000);
		}

		this.formCustomField.get('customField')?.setValue(this.valueSelected);

		this.formCustomField.get('customField')?.valueChanges.subscribe(() => {
			this.fieldForm.emit({
				inputField: this.formCustomField.get('customField') as AbstractControl,
				customFieldData: this.customFieldData
			});
		});

		if (this.customFieldData.type === 'select') {
			this.customFieldData.items.forEach(x => {
				this.selectItem.push({label: x.name, value: x.value, icon: ''});
			});
		}

		if (this.customFieldData.type === 'array') {
			if (this.customFieldData.name.toLowerCase() === 'masterclasses') {
				if(this.globalSrv.masterclasses && this.globalSrv.masterclasses.length > 0){
					this.globalSrv.masterclasses.forEach(x => {
						this.selectItem.push({label: x.label, value: x.id, icon: ''});
					});
				}

				if(this.valueSelected){
					this.valueSelected.forEach(x => {
						const idx = this.globalSrv.masterclasses.findIndex(row => row.id === x);
						//console.log(idx);
						if (idx > -1) {
							this.selectItem.forEach(o => {
								if (o.value === x) {
									this.formCustomField.controls['customField'].setValue(o.value);
								}
							});
						}
					});
				}
			} else {
				if(this.valueSelected){
					this.valueSelected.forEach(x => {
						this.selectItem.push({label: x, value: x, icon: ''});
					});
				}
			}
		}

		if (this.customFieldData.type === 'photo' && this.valueSelected) {
			if(this.valueSelected.hasOwnProperty('url') && this.valueSelected.url !== ''){
				this.imageUrl = this.valueSelected.url;
			} else {
				this.valueSelected = '';
			}
		}

		if (!this.isEditMode) {
			// this.formCustomField.addControl([])
		}

	}

	selectPhotoOrFile(e: any, type: 'photo' | 'file'): void {
		if (e.target.files[0]) {
			this.file = e.target.files[0];
			this.upload(type);
		}
	}

	async upload(type: 'photo' | 'file') {
		const file = this.file;
		const filePath = `events/${this.event_id}/${this.uid}_${type}_${new Date().getTime()}_${file.name}`;
		this.labelUpload = 'Caricamento in corso';
		let task: AngularFireUploadTask;
		if(type === 'photo'){
			const fileResized = await this.globalSrv.resizeImage(512, 512, true, file);
			task = this.storage.upload(filePath, fileResized);
		} else {
			task = this.storage.upload(filePath, file);
		}
		task.snapshotChanges().pipe(
			tap((value: any) => {
					const p = Math.trunc((value.bytesTransferred / value.totalBytes) * 100);
					this.labelUpload = `Caricamento in corso: ${p}%`;
				}
			),
			finalize(() => {
				this.labelUpload = '';
				this.storage.ref(filePath).getDownloadURL().subscribe(res => {
					this.imageUrl = res;
					this.valueSelected = {
						url: res,
						path: filePath
					}
					this.fieldForm.emit({inputField: this.valueSelected, customFieldData: this.customFieldData});
				});
			})
		).subscribe();
	}

	removePhotoOrFile(field_name: string): void {
		this.alertService.requestModal('Attenzione', 'Sei sicuro di voler rimuovere il file?', 'Si', 'No', (bool: any) => {
			if (bool) {
				if(this.valueSelected.hasOwnProperty('path')){
					this.api.customField.removePhotoOrFile(this.event_id, this.uid, field_name).then(() => {
						this.storage.ref(this.valueSelected.path).delete();
						this.labelUpload = '';
						this.valueSelected = undefined;
						this.fieldForm.emit({inputField: '', customFieldData: this.customFieldData});
					});
				}
			}
		});
	}

	// getUrlFromPath(path: string): void {
	// 	console.log(path);
	// 	this.storage.ref(path).getDownloadURL().subscribe(res => {
	// 		this.imageUrl = res;
	// 	});
	// }

	getExtensionFile(fileName: string): any {
		const reg = /([^.]+)(?:\?alt=media)/;
		if (reg.exec(fileName)) {
			// @ts-ignore
			return reg.exec(fileName)[1];

		}
	}

	// changeDate(e: any){
	// 	// Oggetto firebase
	// const ms = new Date(e);
	// 	this.valueSelected = e;
	// 	this.fieldForm.emit({inputField: ms, customFieldData: this.customFieldData});
	// }
}
