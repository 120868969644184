import {Component, EventEmitter, Output} from '@angular/core';
import {MDBModalRef} from 'ng-uikit-pro-standard';

@Component({
	selector: 'app-alert-modal',
	templateUrl: './alert-modal.component.html',
	styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {

	@Output() answerEvent: EventEmitter<boolean> = new EventEmitter();

	title: string | undefined;
	body: string | undefined;
	yesText: string | undefined;
	noText: string | undefined;
	modalState: 'image' | 'alert' | 'request' | undefined;

	constructor(private modalRef: MDBModalRef) {
	}

	closeAlertModal() {
		this.modalRef.hide();
		this.modalRef.content.nome = 'Attenzione';
		this.modalRef.content.body = '';
		if (this.answerEvent !== null) {
			this.answerEvent.emit(false);
		}
	}

	resp(value: boolean) {
		this.answerEvent.emit(value);
		this.modalRef.hide();
		this.modalRef.content.nome = '';
		this.modalRef.content.body = '';
	}

}
