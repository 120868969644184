import {Injectable} from '@angular/core';
import {MDBModalRef, MDBModalService} from 'ng-uikit-pro-standard';
import {AlertModalComponent} from '../core/alert-modal/alert-modal.component';
import {LoadingSpinnerComponent} from '../core/loading-spinner/loading-spinner.component';

@Injectable({
	providedIn: 'root'
})
export class AlertModalService {
	alertModalRef: MDBModalRef | any;
	loadingModalRef: MDBModalRef | any;
	loadingSpinnerShown = false;
	closing = false;

	constructor(private alertModalService: MDBModalService, private loadingModalService: MDBModalService) {
	}

	/**
	 * Mostra un pannello di caricamento
	 * @param textValue: string Testo da visualizzare nel pannello
	 */
	public showLoading(textValue: string): void {
		setTimeout(() => {
			if (this.loadingSpinnerShown) {
				return;
			}
			this.loadingSpinnerShown = true;
			this.loadingModalRef = this.loadingModalService.show(LoadingSpinnerComponent, {
				backdrop: true,
				keyboard: false,
				focus: true,
				show: true,
				ignoreBackdropClick: true,
				class: 'modal-dialog-centered',
				containerClass: '',
				animated: true,
			});
			this.loadingModalRef.content.loadingText = textValue;
		}, 0);
	}

	/**
	 * Chiude il pannello di caricamento
	 */
	public closeLoading(): void {
		// attendo prima di chiudere il pannello
		setTimeout(() => {
			if (!this.loadingModalRef) {
				return;
			}

			this.loadingModalRef.hide();
			this.loadingSpinnerShown = false;
		}, 250);
	}

	/**
	 * Aggiorno il testo nel pannello di caricamento
	 * @param textValue: string
	 */
	public updateLoadingText(textValue: string): void {
		if (!this.loadingModalRef) {
			return;
		}

		this.loadingModalRef.content.loadingText = textValue;
	}

	/**
	 * Apro il pannello alert di default con vari parametri
	 * @param title: string Titolo del pannello
	 * @param body: string Corpo del messaggio da mostrare
	 * @param showBg: boolean Mostra il background (default true)
	 * @param event: any Evento da avviare all'apertura del pannello (default null)
	 */
	openAlertModal(title: string, body: string, showBg = true, event: any = null) {
		if (this.closing) {
			this.closing = false;
			this.alertModalRef.content.title = title;
			this.alertModalRef.content.body = body;
		}

		this.alertModalRef = this.alertModalService.show(AlertModalComponent, {
			backdrop: showBg,
			keyboard: false,
			focus: true,
			show: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered',
			containerClass: '',
			animated: true,
		});
		this.alertModalRef.content.modalState = 'alert';
		this.alertModalRef.content.title = title;
		this.alertModalRef.content.body = body.replace(/\n|\n\r|\r\n/g, '<br>');
		if (event !== null) {
			this.alertModalRef.content.answerEvent.subscribe(event);
		}
	}

	/**
	 * Chiudo il pannello alert
	 */
	closeAlertModal() {
		if (!this.alertModalRef || this.closing) {
			return;
		}

		this.closing = true;

		// attendo prima di chiudere il pannello
		setTimeout(() => {
			if (!this.closing) {
				return;
			}
			this.closing = false;
			this.alertModalRef.hide();
			this.alertModalRef.content.title = 'Attenzione';
			this.alertModalRef.content.body = '';
		}, 250);

	}

	/**
	 * Apro un pannello con un'immagine
	 * @param title: string Titolo del pannello
	 * @param imageUrl: string URL dell'immagine da aprire
	 */
	openImageModal(title: string, imageUrl: string) {
		this.alertModalRef = this.alertModalService.show(AlertModalComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered',
			containerClass: '',
			animated: true
		});
		this.alertModalRef.content.modalState = 'image';
		this.alertModalRef.content.title = title;
		this.alertModalRef.content.body = imageUrl;
	}

	/**
	 * Apro un pannello di richiesta all'utente con TRE opzioni
	 * @param title: string Titolo del pannello
	 * @param body: string Corpo del messaggio del pannello
	 * @param yesText: Testo nel tasto di conferma
	 * @param noText: string Testo nel tasto di annullamento
	 * @param event: any Evento da avviare alla chiusura del pannello
	 */
	requestModal(title: string, body: string, yesText: string, noText: string, event: any) {
		this.alertModalRef = this.alertModalService.show(AlertModalComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered',
			containerClass: '',
			animated: true
		});
		this.alertModalRef.content.modalState = 'request';
		this.alertModalRef.content.title = title;
		this.alertModalRef.content.body = body.replace(/\n|\n\r|\r\n/g, '<br>');
		this.alertModalRef.content.yesText = yesText || 'Si';
		this.alertModalRef.content.noText = noText || 'No';
		this.alertModalRef.content.answerEvent.subscribe(event);
	}
}
