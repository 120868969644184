import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {GlobalService} from '../../services/global.service';

@Directive({
	selector: '[appModuliRole]'
})
export class ModuliRoleDirective implements OnInit {
	@Input() appModuliRole: string;

	constructor(
		private service: GlobalService,
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>,
		private auth: AuthService) {
	}

	ngOnInit(): void {
		this.auth.getModuliUser().then(res => {
			const moduli = res as string[];
			if (moduli.includes(this.appModuliRole) && this.service.evento.moduli && this.service.evento.moduli.includes(this.appModuliRole)) {
				this.viewContainerRef.createEmbeddedView(this.templateRef); // renderizza il tag da cui viene chiamata la direttiva
			} else {
				this.viewContainerRef.clear(); // Rimuove il tag dall'html
			}
		});
	}
}
