import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../services/notifications.service';
import {AlertModalService} from '../../services/alert-modal.service';
import {AuthService} from '../../services/auth.service';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

	token = '';
	email = '';

	btnLoading = false;
	tokenExpired = false;
	loadingToken = false;

	formPsw: FormGroup = new FormGroup({
		password: new FormControl('', [Validators.required, Validators.pattern(/(?=^.{8,}$)(?=.*\d)(?=.*[-_?!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)]),
		rePassword: new FormControl('', [Validators.required, Validators.pattern(/(?=^.{8,}$)(?=.*\d)(?=.*[-_?!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)])
	});

	constructor(private route: ActivatedRoute, private api: ApiService, private notification: NotificationsService, private router: Router, private auth: AuthService, private alertService: AlertModalService) {
	}

	ngOnInit(): void {
		this.route.params.subscribe(res => {
			if (res.hasOwnProperty('code')) {
				this.token = res.code;
				this.api.auth.verifyCodeResetPassword(this.token).then(resEmail => {
					this.email = resEmail;
					this.loadingToken = true;
				}).catch(err => {
					if (err.code === 'code/token_expired') {
						this.tokenExpired = true;
					}
				});
			}
		});
	}

	updatePassword(): void {
		if (this.formPsw.invalid) {
			this.notification.showErrorNotification('Compila tutti i campi richiesti');
			return;
		}

		if (this.formPsw.get('password')?.value !== this.formPsw.get('rePassword')?.value) {
			this.notification.showErrorNotification('Le password non coincidono');
			return;
		}

		this.btnLoading = true;

		this.api.auth.changePasswordWithToken(this.token, this.formPsw.get('password')?.value).then(() => {
			this.alertService.openAlertModal('Attenzione', 'Password cambiata con successo.', true, (() => {
				this.auth.logOut();
			}));
		}).catch(err => {
			this.btnLoading = false;
			if (err.code === 'code/password_already_used') {
				this.notification.showErrorNotification('Questa password è già stata utilizzata');
			} else if (err.code === 'code/invalid_password') {
				this.notification.showErrorNotification('Password non valida, controllare le specifiche della password');
			} else {
				this.notification.showErrorNotification('Errore nell\'aggiornamento della password');
			}
			return;
		});

	}

}
