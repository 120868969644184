import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IFilter} from '../../interfaces/IFilter';
import {ISorting} from '../../interfaces/ISorting';
import {IUser} from '../../interfaces';

@Component({
	selector: 'app-table-manager',
	templateUrl: './table-manager.component.html',
	styleUrls: ['./table-manager.component.scss']
})
export class TableManagerComponent implements OnInit, AfterViewInit {

	rowSize = 10;
	currentPage = 1;
	totalPage = 1;

	@Input() items: any[] = [];
	@Input() mobile: boolean = false;
	@Output() itemsPagination: EventEmitter<any[]> = new EventEmitter();

	allItems: any[] = [];
	sorting: ISorting = {field: '', direction: ''};
	appliedFilters: IFilter[] = [];
	appliedSorting: {fieldType: string, isCustom: boolean, customOrder?: string[]} = {fieldType: '', isCustom: false};

	schemaOspiteAccompagnatore = false;

	constructor() {
	}

	ngOnInit(): void {
		this.rowSize = parseInt(localStorage.getItem('rowSize') || '10', 10);
	}

	ngAfterViewInit(): void {
		if(this.items.length > 0){
			this.allItems = [...this.items];
		}
		this.totalPage = Math.ceil(this.items.length / this.rowSize);

		this.sendItems();
	}

	loadRowSize(): void {
		this.rowSize = parseInt(localStorage.getItem('rowSize') || '10', 10);
	}

	updateItems(items: any): any {
		this.items = [...items];
		const page = this.currentPage;
		const sorting = {...this.sorting};
		if(this.appliedFilters && this.appliedFilters.length > 0){
			this.filter(this.appliedFilters);
		}
		this.sorting = {...sorting};
		if(this.appliedSorting && this.sorting.field !== '' && this.sorting.direction !== ''){
			this.sortNoChangingDirection(this.sorting.field, this.appliedSorting.fieldType, this.appliedSorting.isCustom, this.sorting.direction, this.appliedSorting.customOrder);
		}
		this.currentPage = page;
		this.totalPage = Math.ceil(this.items.length / this.rowSize);
		this.sendItems();
	}

	nextBtn(): void {
		if (this.currentPage !== this.totalPage) {
			this.currentPage++;
			this.sendItems();
		}
	}

	prevBtn(): void {
		if (this.currentPage !== 1) {
			this.currentPage--;
			this.sendItems();
		}
	}

	lastPageBtn(): void {
		this.currentPage = this.totalPage;
		this.sendItems();
	}

	firstPageBtn(): void {
		this.currentPage = 1;
		this.sendItems();
	}

	sendItems(): void {
		this.totalPage = Math.ceil(this.items.length / this.rowSize);
		const firstItem = (this.currentPage * this.rowSize) - this.rowSize;
		const result = this.items.slice(firstItem, firstItem + this.rowSize);
		if(result.length === 0){
			this.currentPage = 0;
		}
		this.itemsPagination.emit(result);
	}

	changeSizeRow(n: number): void {
		this.rowSize = n;
		this.currentPage = 1;
		this.totalPage = Math.ceil(this.items.length / this.rowSize);
		this.sendItems();
		this.saveSizeRow();
	}

	emptyItems(): void {
		this.items = [];
		this.currentPage = 1;
		this.sendItems();
	}

	saveSizeRow(): void {
		// COOKIE
		localStorage.setItem('rowSize', this.rowSize.toString());
	}

	/**
	 * Mette in ordine gli elementi a seconda del campo, del tipo e se sia customField o meno
	 * La funzione viene attivata alla pressione del pulsante per l'ordinamento, viene gestito il cambio di ordinamento
	 * @param sortingField
	 * @param sortingFieldType
	 * @param isCustomField
	 */
	sort(sortingField: string, sortingFieldType: string, isCustomField: boolean, customOrder?: string[]){
		// Gestione direzione
		if(sortingField !== this.sorting.field){
			this.sorting.direction = '';
		}
		switch (this.sorting.direction) {
			case 'asc': {
				if(customOrder){
					customOrder.reverse();
				}
				this.sortNoChangingDirection(sortingField, sortingFieldType, isCustomField, 'desc', customOrder);
				break;
			}
			case 'desc': {
				this.reset();
				this.currentPage = 1;
				this.sendItems();
				break;
			}
			case '': {
				this.sortNoChangingDirection(sortingField, sortingFieldType, isCustomField, 'asc', customOrder);
				break;
			}
		}
	}


	/**
	 * Applica l'ordinamento che gli viene dato, senza la gestione della direzione
	 * @param sortingField
	 * @param sortingFieldType
	 * @param isCustomField
	 * @param sortUno
	 */
	sortNoChangingDirection(sortingField: string, sortingFieldType: string, isCustomField: boolean, direction: string, customOrder?: string[]){
		// Get type of the field
		let sortUno;
		if(direction === 'asc'){
			sortUno = 1;
		} else if(direction === 'desc'){
			sortUno = -1;
		} else{
			return;
		}
		this.sorting.direction = direction;
		this.sorting.field = sortingField;
		this.appliedSorting.fieldType = sortingFieldType;
		this.appliedSorting.isCustom = isCustomField;
		if(customOrder){
			this.appliedSorting.customOrder = [...customOrder];
		}
		switch(sortingFieldType){
			case 'string':{
				if(isCustomField){
					this.items.sort((a, b) => {
						let fieldA;
						let fieldB;
						if(a.custom_fields[sortingField]){
							fieldA = a.custom_fields[sortingField].toLowerCase();
						}
						if(b.custom_fields[sortingField]){
							fieldB = b.custom_fields[sortingField].toLowerCase();
						}
						return this.sortField(fieldA, fieldB, sortUno);
					});
				} else {
					this.items.sort((a, b) => {
						let fieldA = a[sortingField];
						let fieldB = b[sortingField];
						if(a[sortingField]){
							fieldA = a[sortingField].toLowerCase();
						}
						if(b[sortingField]){
							fieldB = b[sortingField].toLowerCase();
						}
						return this.sortField(fieldA, fieldB, sortUno);
					});
				}
				break;
			}
			case 'number':{
				if(isCustomField){
					this.items.sort((a, b) => {
						const fieldA = a.custom_fields[sortingField];
						const fieldB = b.custom_fields[sortingField];
						return this.sortField(fieldA, fieldB, sortUno);
					});
				} else {
					this.items.sort((a, b) => {
						const fieldA = a[sortingField];
						const fieldB = b[sortingField];
						return this.sortField(fieldA, fieldB, sortUno);
					});
				}
				break;
			}
			case 'boolean':{
				if(isCustomField){
					this.items.sort((a, b) => (a.custom_fields[sortingField] === b.custom_fields[sortingField]) ? 0 : (a.custom_fields[sortingField] ? -sortUno : sortUno));
				} else {
					this.items.sort((a, b) => (a[sortingField] === b[sortingField]) ? 0 : (a[sortingField] ? -sortUno : sortUno));
				}
				break;
			}
			case 'date':{
				if(isCustomField){
					this.items.sort((a, b) => {
						let fieldA;
						let fieldB;
						if(Array.isArray(a.custom_fields[sortingField]) && Array.isArray(b.custom_fields[sortingField]) &&
							a.custom_fields[sortingField].length > 0 && b.custom_fields[sortingField].length > 0 &&
							a.custom_fields[sortingField][a.custom_fields[sortingField].length -1].hasOwnProperty('seconds') &&
							b.custom_fields[sortingField][b.custom_fields[sortingField].length -1].hasOwnProperty('seconds')
						){
							if(a.custom_fields[sortingField][a.custom_fields[sortingField].length -1].hasOwnProperty('seconds') && b.custom_fields[sortingField][b.custom_fields[sortingField].length -1].hasOwnProperty('seconds')){
								fieldA = a.custom_fields[sortingField][a.custom_fields[sortingField].length -1].seconds;
								fieldB = b.custom_fields[sortingField][b.custom_fields[sortingField].length -1].seconds;
							} else if(a.custom_fields[sortingField][a.custom_fields[sortingField].length -1].hasOwnProperty('_seconds') && b.custom_fields[sortingField][b.custom_fields[sortingField].length -1].hasOwnProperty('_seconds')){
								fieldA = a.custom_fields[sortingField][a.custom_fields[sortingField].length -1]._seconds;
								fieldB = b.custom_fields[sortingField][b.custom_fields[sortingField].length -1]._seconds;
							} else {
								fieldA = a.custom_fields[sortingField][a.custom_fields[sortingField].length -1];
								fieldB = b.custom_fields[sortingField][b.custom_fields[sortingField].length -1];
							}
						} else if((a.custom_fields[sortingField] && a.custom_fields[sortingField].hasOwnProperty('seconds')) || (b.custom_fields[sortingField] && b.custom_fields[sortingField].hasOwnProperty('seconds'))){
							fieldA = a.custom_fields[sortingField] ? a.custom_fields[sortingField].seconds : undefined;
							fieldB = b.custom_fields[sortingField] ? b.custom_fields[sortingField].seconds : undefined;
						} else if((a.custom_fields[sortingField] && a.custom_fields[sortingField].hasOwnProperty('_seconds')) || (b.custom_fields[sortingField] && b.custom_fields[sortingField].hasOwnProperty('_seconds'))){
							fieldA = a.custom_fields[sortingField] ? a.custom_fields[sortingField]._seconds : undefined;
							fieldB = b.custom_fields[sortingField] ? b.custom_fields[sortingField]._seconds : undefined;
						} else {
							fieldA = a.custom_fields[sortingField];
							fieldB = b.custom_fields[sortingField];
						}
						return this.sortField(fieldA, fieldB, sortUno);
					});
				} else {
					this.items.sort((a, b) => {
						let fieldA;
						let fieldB;
						if(Array.isArray(a[sortingField]) && Array.isArray(b[sortingField]) &&
							a[sortingField].length > 0 && b[sortingField].length > 0
						){
							if(a[sortingField][a[sortingField].length -1].hasOwnProperty('seconds') && b[sortingField][b[sortingField].length -1].hasOwnProperty('seconds')){
								fieldA = a[sortingField][a[sortingField].length -1].seconds;
								fieldB = b[sortingField][b[sortingField].length -1].seconds;
							} else if(a[sortingField][a[sortingField].length -1].hasOwnProperty('_seconds') && b[sortingField][b[sortingField].length -1].hasOwnProperty('_seconds')){
								fieldA = a[sortingField][a[sortingField].length -1]._seconds;
								fieldB = b[sortingField][b[sortingField].length -1]._seconds;
							} else {
								fieldA = a[sortingField][a[sortingField].length -1];
								fieldB = b[sortingField][b[sortingField].length -1];
							}
						} else if(a[sortingField] && a[sortingField].hasOwnProperty('seconds') && b[sortingField] && b[sortingField].hasOwnProperty('seconds')){
							fieldA = a[sortingField].seconds;
							fieldB = b[sortingField].seconds;
						} else if(a[sortingField] && a[sortingField].hasOwnProperty('_seconds') && b[sortingField] && b[sortingField].hasOwnProperty('_seconds')){
							fieldA = a[sortingField]._seconds;
							fieldB = b[sortingField]._seconds;
						} else {
							fieldA = a[sortingField];
							fieldB = b[sortingField];
						}
						return this.sortField(fieldA, fieldB, sortUno);
					});
				}
				break;
			}
			case 'array':{
				if(isCustomField){
					this.items.sort((a, b) => {
						const fieldA = JSON.stringify(a.custom_fields[sortingField]);
						const fieldB = JSON.stringify(b.custom_fields[sortingField]);
						return this.sortField(fieldA, fieldB, sortUno);
					});
				} else {
					this.items.sort((a, b) => {
						const fieldA = JSON.stringify(a[sortingField]);
						const fieldB = JSON.stringify(b[sortingField]);
						return this.sortField(fieldA, fieldB, sortUno);
					});
				}
				break;
			}
			case 'time':{
				if(isCustomField){
					this.items.sort((a, b) => {
						const fieldA = a.custom_fields[sortingField] && a.custom_fields[sortingField] !== '' ? new Date('1970-01-01 ' + a.custom_fields[sortingField]) : undefined;
						const fieldB = b.custom_fields[sortingField] && b.custom_fields[sortingField] !== '' ? new Date('1970-01-01 ' + b.custom_fields[sortingField]) : undefined;
						return this.sortField(fieldA, fieldB, sortUno);
					});
				} else {
					this.items.sort((a, b) => {
						const fieldA = a[sortingField] && a[sortingField] !== '' ? new Date('1970-01-01 ' + a[sortingField]) : undefined;
						const fieldB = b[sortingField] && b[sortingField] !== '' ? new Date('1970-01-01 ' + b[sortingField]) : undefined;
						return this.sortField(fieldA, fieldB, sortUno);
					});
				}
				break;
			}
			case 'custom':{
				if(isCustomField){
					this.items.sort((a, b) => {
						const indexA = customOrder.indexOf(a.custom_fields[sortingField]);
						const indexB = customOrder.indexOf(b.custom_fields[sortingField]);
						if (indexA === -1 && indexB === -1) {
							return 0;
						} else if (indexA === -1) {
							return 1;
						} else if (indexB === -1) {
							return -1;
						}
						return indexA - indexB;
					});
				} else {
					this.items.sort((a, b) => {
						const indexA = customOrder.indexOf(a[sortingField]);
						const indexB = customOrder.indexOf(b[sortingField]);
						if (indexA === -1 && indexB === -1) {
							return 0;
						} else if (indexA === -1) {
							return 1;
						} else if (indexB === -1) {
							return -1;
						}
						return indexA - indexB;
					});
				}
				break;
			}
			case 'select':{ // La casistica select viene gestita come il number
				if(isCustomField){
					this.items.sort((a, b) => {
						const fieldA = a.custom_fields[sortingField];
						const fieldB = b.custom_fields[sortingField];
						return this.sortField(fieldA, fieldB, sortUno);
					});
				} else {
					this.items.sort((a, b) => {
						const fieldA = a[sortingField];
						const fieldB = b[sortingField];
						return this.sortField(fieldA, fieldB, sortUno);
					});
				}
				break;
			}
			default:{
				// Se seleziono un tipo di campo non ordinabile rimuovo l'ordinamento
				this.sorting.direction = '';
				this.sorting.field = '';
				this.appliedSorting = {fieldType: '', isCustom: false};
				this.reset();
				break;
			}
		}
		this.currentPage = 1;
		this.sendItems();
	}

	/**
	 * Funzione da inserire nel sorting in modo da lasciare in fondo gli undefined
	 * @param fieldA
	 * @param fieldB
	 * @param sortUno
	 */
	sortField(fieldA: any, fieldB: any, sortUno: number){
		if (fieldA === undefined && fieldB === undefined) {
			return 0;
		} else if (fieldA === undefined) {
			return 1;
		} else if (fieldB === undefined) {
			return -1;
		} else {
			return (fieldA > fieldB) ? sortUno : (fieldB > fieldA) ? -sortUno : 0;
		}
	}

	/**
	 * La funzione prende in ingresso un array di filtri e li applica uno dietro l'altro
	 * @param filters
	 */
	filter(filters: IFilter[]){
		if(filters){
			this.appliedFilters = [...filters];
		} else {
			this.appliedFilters = [];
		}
		this.reset(true);
		if(filters && filters.length > 0){
			for(const filter of filters){
				switch (filter.operator) {
					case '=': {
						switch (filter.type) {
							case 'string': {
								if(filter.isCustomField){
									this.items = this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field].toLowerCase() === filter.value.toLowerCase();
										} else {
											return false;
										}
									});
								} else {
									this.items = this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field].toLowerCase() === filter.value.toLowerCase();
										} else {
											return false;
										}
									});
								}
								break;
							}
							case 'boolean': {
								if(filter.isCustomField){
									this.items = this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field] === true || x.custom_fields[filter.field] === 1;
										} else {
											return false;
										}
									});
								} else {
									this.items = this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field] === true || x[filter.field] === 1;
										} else {
											return false;
										}
									});
								}
								break;
							}
							case 'date': {
								// console.log(filter);
								filter.value2 = filter.value + (24 * 60 * 60);
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											let el = x.custom_fields[filter.field];
											if(el.hasOwnProperty('_seconds')){
												return el._seconds >= filter.value && el._seconds < filter.value2;
											} else if(el.hasOwnProperty('seconds')){
												return el.seconds >= filter.value && el.seconds < filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('seconds')) {
												const last = el.length - 1;
												return el[last].seconds >= filter.value && el[last].seconds < filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('_seconds')) {
												const last = el.length - 1;
												return el[last]._seconds >= filter.value && el[last]._seconds < filter.value2;
											} else {
												return false;
											}
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											let el = x[filter.field];
											if(el.hasOwnProperty('_seconds')){
												return el._seconds >= filter.value && el._seconds < filter.value2;
											} else if(el.hasOwnProperty('seconds')){
												return el.seconds >= filter.value && el.seconds < filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('seconds')) {
												const last = el.length - 1;
												return el[last].seconds >= filter.value && el[last].seconds < filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('_seconds')) {
												const last = el.length - 1;
												return el[last]._seconds >= filter.value && el[last]._seconds < filter.value2;
											} else {
												return false;
											}
										} else {
											return false;
										}
									})];
								}
								break;
							}
							case 'select': {
								if(filter.isCustomField){
									this.items = this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field].toString() === filter.value;
										} else {
											return false;
										}
									});
								} else {
									this.items = this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field].toString() === filter.value;
										} else {
											return false;
										}
									});
								}
								break;
							}
							case 'photo': {
								if(filter.isCustomField){
									this.items = this.items.filter(x => {
										return (x.custom_fields[filter.field] && x.custom_fields[filter.field].hasOwnProperty('url') && x.custom_fields[filter.field].url !== '');
									});
								} else {
									this.items = this.items.filter(x => {
										return (x[filter.field] && x[filter.field].hasOwnProperty('url') && x[filter.field].url !== '');
									});
								}
								break;
							}
							default: {
								if(filter.isCustomField){
									this.items = this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field] === filter.value;
										} else {
											return false;
										}
									});
								} else {
									this.items = this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field] === filter.value;
										} else {
											return false;
										}
									});
								}
								break;
							}
						}
						break;
					}
					case '!=': {
						switch (filter.type) {
							case 'string': {
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field].toLowerCase() !== filter.value.toLowerCase();
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field].toLowerCase() !== filter.value.toLowerCase();
										} else {
											return false;
										}
									})];
								}
								break;
							}
							case 'boolean': {
								if(filter.isCustomField){
									this.items = this.items.filter(x => {
										if(x.custom_fields[filter.field] || x.custom_fields[filter.field] === 0){
											return false;
										} else {
											return true;
										}
									});
								} else {
									this.items = this.items.filter(x => {
										if(x[filter.field] || x[filter.field] === 0){
											return false;
										} else {
											return true;
										}
									});
								}
								break;
							}
							case 'date': {
								filter.value2 = filter.value + (24 * 60 * 60);
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											let el = x.custom_fields[filter.field];
											if(el.hasOwnProperty('_seconds')){
												return !(el._seconds >= filter.value && el._seconds < filter.value2);
											} else if(el.hasOwnProperty('seconds')){
												return !(el.seconds >= filter.value && el.seconds < filter.value2);
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('seconds')) {
												const last = el.length - 1;
												return !(el[last].seconds >= filter.value && el[last].seconds < filter.value2);
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('_seconds')) {
												const last = el.length - 1;
												return !(el[last]._seconds >= filter.value && el[last]._seconds < filter.value2);
											} else {
												return false;
											}
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											let el = x[filter.field];
											if(el.hasOwnProperty('_seconds')){
												return !(el._seconds >= filter.value && el._seconds < filter.value2);
											} else if(el.hasOwnProperty('seconds')){
												return !(el.seconds >= filter.value && el.seconds < filter.value2);
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('seconds')) {
												const last = el.length - 1;
												return !(el[last].seconds >= filter.value && el[last].seconds < filter.value2);
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('_seconds')) {
												const last = el.length - 1;
												return !(el[last]._seconds >= filter.value && el[last]._seconds < filter.value2);
											} else {
												return false;
											}
										} else {
											return false;
										}
									})];
								}
								break;
							}
							case 'select': {
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field].toString() !== filter.value;
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field].toString() !== filter.value;
										} else {
											return false;
										}
									})];
								}
								break;
							}
							case 'photo': {
								if(filter.isCustomField){
									this.items = this.items.filter(x => {
										return (!x.custom_fields[filter.field] || !x.custom_fields[filter.field].hasOwnProperty('url') || x.custom_fields[filter.field].url === '');
									});
								} else {
									this.items = this.items.filter(x => {
										return (!x[filter.field] || !x[filter.field].hasOwnProperty('url') || x[filter.field].url === '');
									});
								}
								break;
							}
							default: {
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field] !== filter.value;
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field] !== filter.value;
										} else {
											return false;
										}
									})];
								}
								break;
							}
						}
						break;
					}
					case 'in': {
						switch (filter.type) {
							case 'string': {
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field].toLowerCase().includes(filter.value.toLowerCase());
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field].toLowerCase().includes(filter.value.toLowerCase());
										} else {
											return false;
										}
									})];
								}
								break;
							}
							case 'date': {
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											let el = x.custom_fields[filter.field];
											if(el.hasOwnProperty('_seconds')){
												return el._seconds >= filter.value && el._seconds <= filter.value2;
											} else if(el.hasOwnProperty('seconds')){
												return el.seconds >= filter.value && el.seconds <= filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('seconds')) {
												const last = el.length - 1;
												return el[last].seconds >= filter.value && el[last].seconds <= filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('_seconds')) {
												const last = el.length - 1;
												return el[last]._seconds >= filter.value && el[last]._seconds <= filter.value2;
											} else {
												return false;
											}
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											let el = x[filter.field];
											if(el.hasOwnProperty('_seconds')){
												return el._seconds >= filter.value && el._seconds <= filter.value2;
											} else if(el.hasOwnProperty('seconds')){
												return el.seconds >= filter.value && el.seconds <= filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('seconds')) {
												const last = el.length - 1;
												return el[last].seconds >= filter.value && el[last].seconds <= filter.value2;
											} else if(Array.isArray(el) && el[el.length-1].hasOwnProperty('_seconds')) {
												const last = el.length - 1;
												return el[last]._seconds >= filter.value && el[last]._seconds <= filter.value2;
											} else {
												return false;
											}
										} else {
											return false;
										}
									})];
								}
								break;
							}
							case 'time': {
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											let el = new Date('1970-01-01 ' + x.custom_fields[filter.field]);
											return el >= new Date('1970-01-01 ' + filter.value) && el <= new Date('1970-01-01 ' + filter.value2);
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										this.items = [...this.items.filter(x => {
											if(x[filter.field]){
												let el = new Date('1970-01-01 ' + x[filter.field]);
												return el >= new Date('1970-01-01 ' + filter.value) && el <= new Date('1970-01-01 ' + filter.value2);
											} else {
												return false;
											}
										})];
									})];
								}
								break;
							}
							default: {
								if(filter.isCustomField){
									this.items = [...this.items.filter(x => {
										if(x.custom_fields[filter.field]){
											return x.custom_fields[filter.field].includes(filter.value);
										} else {
											return false;
										}
									})];
								} else {
									this.items = [...this.items.filter(x => {
										if(x[filter.field]){
											return x[filter.field].includes(filter.value);
										} else {
											return false;
										}
									})];
								}
								break;
							}
						}
						break;
					}
					case 'empty':{
						if(filter.type === 'array'){
							if(filter.isCustomField){
								this.items = [...this.items.filter(x => {
									if(x.custom_fields[filter.field] || (Array.isArray(x.custom_fields[filter.field]) && x.custom_fields[filter.field].length === 0)){
										return false;
									} else {
										return true;
									}
								})];
							} else {
								this.items = [...this.items.filter(x => {
									if(x[filter.field] || (Array.isArray(x[filter.field]) && x[filter.field].length === 0)){
										return false;
									} else {
										return true;
									}
								})];
							}
						} else {
							if(filter.isCustomField){
								this.items = [...this.items.filter(x => {
									if(x.custom_fields[filter.field]){
										return false;
									} else {
										return true;
									}
								})];
							} else {
								this.items = [...this.items.filter(x => {
									if(x[filter.field]){
										return false;
									} else {
										return true;
									}
								})];
							}
						}
						break;
					}
				}
			}
		}
		this.currentPage = 1;
		this.totalPage = Math.ceil(this.items.length / this.rowSize);
		this.sendItems();
	}

	reset(isFilter?: boolean){
		this.items = [...this.allItems];
		this.currentPage = 1;
		this.sorting.direction = '';
		this.sorting.field = '';
		if(this.schemaOspiteAccompagnatore){
			this.items = this.ordinaOspitiAccompagnatore(this.items);
		}
		if(!isFilter){
			this.filter(this.appliedFilters);
		}
	}

	/**
	 * Ordina un array di utenti, raggruppando gli ospiti con i loro accompagnatori.
	 *
	 * Ogni ospite principale (identificato dall'avere un campo 'guest_uid') viene seguito nel risultato
	 * dal suo accompagnatore (identificato dall'avere un campo 'main_data' con 'uid' uguale all'id dell'ospite).
	 * Gli utenti che non sono né ospiti principali né accompagnatori vengono semplicemente inclusi nell'array risultante.
	 *
	 * @param {IUser[]} arr - L'array di utenti da ordinare.
	 * @returns {IUser[]} L'array di utenti ordinato, con ogni ospite seguito dal suo accompagnatore.
	 */
	ordinaOspitiAccompagnatore(arr: IUser[]): IUser[] {
		// Mappa per la ricerca veloce degli utenti per ID
		const idToUserMap: Map<string, IUser> = new Map(arr.map(user => [user.id, user]));

		// Mappa per memorizzare l'array ordinato
		const sortedMap: Map<string, IUser> = new Map();

		// Itera su tutti gli utenti nell'array di input
		for (const user of arr) {
			// Se l'utente esiste già nella mappa ordinata o ha un campo main_data, passa al prossimo utente
			if (sortedMap.has(user.id) || user.hasOwnProperty('main_data')) continue;

			// Aggiunge l'utente alla mappa ordinata
			sortedMap.set(user.id, user);

			// Se l'utente ha un accompagnatore (guest_uid), lo cerca e lo aggiunge alla mappa ordinata
			if (user.hasOwnProperty('guest_uid') && user.guest_uid !== '') {
				const accompagnatore: IUser = idToUserMap.get(user.guest_uid);
				// Se l'accompagnatore è valido e il campo main_data.uid è uguale all'id dell'utente lo aggiungo alla variabile sortedMap
				if (accompagnatore && accompagnatore.hasOwnProperty('main_data') && accompagnatore.main_data.uid === user.id) {
					sortedMap.set(user.guest_uid, accompagnatore);
				}
			}
		}

		if(arr.length !== sortedMap.size){
			console.warn(`Trovati ${Math.abs(arr.length - sortedMap.size)} mismatch accompagnatori e ospiti`);
		}

		// Converte la mappa ordinata in un array e la restituisce
		return Array.from(sortedMap.values());
	}

}
