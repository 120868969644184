import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatepickerModule, MDBBootstrapModulesPro, MdbTimePickerModule} from 'ng-uikit-pro-standard';
import {AlertModalComponent} from './alert-modal/alert-modal.component';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmptyFieldPipe} from './pipe/empty-field.pipe';
import {CustomFieldInputComponent} from './custom-field-input/custom-field-input.component';
import {FormLandingComponent} from './form-landing/form-landing.component';
import {RequiredInputDirective} from './directives/required-input.directive';
import {RoleDirective} from './directives/role.directive';
import {PaginationTableComponent} from './pagination-table/pagination-table.component';
import {TruncatePipe} from './pipe/truncate.pipe';
import {BtnLoaderComponent} from './btn-loader/btn-loader.component';
import {TransformDatePipe} from './pipe/transform-date.pipe';
import {DataTappaPipe} from './pipe/data-tappa.pipe';
import {ModuliRoleDirective} from './directives/moduli-role.directive';
import {UserViewComponent} from './user-view/user-view.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TransformTimestampPipe} from './pipe/transform-timestamp.pipe';
import {TableManagerComponent} from './table-manager/table-manager.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        MDBBootstrapModulesPro.forRoot(),
        DatepickerModule,
        MdbTimePickerModule
    ],
    declarations: [
        AlertModalComponent,
        BtnLoaderComponent,
        CustomFieldInputComponent,
        DataTappaPipe,
        EmptyFieldPipe,
        LoadingSpinnerComponent,
        ModuliRoleDirective,
        FormLandingComponent,
        PaginationTableComponent,
        TableManagerComponent,
        RequiredInputDirective,
        RoleDirective,
        TransformDatePipe,
        TransformTimestampPipe,
        TruncatePipe,
        UserViewComponent
    ],
    // entryComponents: [AlertModalComponent, LoadingSpinnerComponent],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        DataTappaPipe,
        BtnLoaderComponent,
        CustomFieldInputComponent,
        EmptyFieldPipe,
        FormLandingComponent,
        LoadingSpinnerComponent,
        MDBBootstrapModulesPro,
        ModuliRoleDirective,
        PaginationTableComponent,
        TableManagerComponent,
        RequiredInputDirective,
        RoleDirective,
        TransformDatePipe,
        TransformTimestampPipe,
        TruncatePipe,
        UserViewComponent,
        DatepickerModule,
        MdbTimePickerModule
    ]
})
export class CoreModule {
}
