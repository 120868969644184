import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Directive({
    selector: '[appRole]'
})
export class RoleDirective implements OnInit {

    @Input() appRole: number[];

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private auth: AuthService) {
    }

    ngOnInit(): void {
        const role = this.auth.getRoleUser();
        if (role >= 0 && this.appRole.includes(role)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }
    }

}
