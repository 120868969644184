import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
	selector: 'app-pagination-table',
	templateUrl: './pagination-table.component.html',
	styleUrls: ['./pagination-table.component.scss']
})
export class PaginationTableComponent implements OnInit, AfterViewInit, OnChanges {

	rowSize = 10;
	currentPage = 1;
	totalPage = 1;

	@Input() items: any[] = [];
	@Output() itemsPagination: EventEmitter<any[]> = new EventEmitter();

	constructor() {
	}

	ngOnInit(): void {
		this.rowSize = parseInt(localStorage.getItem('rowSize') || '10', 10);
	}

	ngAfterViewInit(): void {
		this.totalPage = Math.ceil(this.items.length / this.rowSize);
		this.sendItems();
	}

	ngOnChanges(changes: SimpleChanges): void {
		setTimeout(() => {
			if (!changes.items.firstChange) {
				this.items = changes.items.currentValue;
				this.currentPage = 1;
				this.totalPage = Math.ceil(this.items.length / this.rowSize);
				this.sendItems();
			}
		}, 5);
	}

	loadRowSize(): void {
		this.rowSize = parseInt(localStorage.getItem('rowSize') || '10', 10);
	}

	updateUser(users: any): any {
		this.items = users;
		this.currentPage = 1;
		this.totalPage = Math.ceil(this.items.length / this.rowSize);
		this.sendItems();
	}

	nextBtn(): void {
		if (this.currentPage !== this.totalPage) {
			this.currentPage++;
			this.sendItems();
		}
	}

	prevBtn(): void {
		if (this.currentPage !== 1) {
			this.currentPage--;
			this.sendItems();
		}
	}

	lastPageBtn(): void {
		this.currentPage = this.totalPage;
		this.sendItems();
	}

	firstPageBtn(): void {
		this.currentPage = 1;
		this.sendItems();
	}

	sendItems(): void {
		const firstItem = (this.currentPage * this.rowSize) - this.rowSize;
		const result = this.items.slice(firstItem, firstItem + this.rowSize);
		this.itemsPagination.emit(result);
	}

	changeSizeRow(n: number): void {
		this.rowSize = n;
		this.currentPage = 1;
		this.totalPage = Math.ceil(this.items.length / this.rowSize);
		this.sendItems();
		this.saveSizeRow();
	}

	emptyItems(): void {
		this.items = [];
		this.sendItems();
	}

	saveSizeRow(): void {
		// COOKIE
		localStorage.setItem('rowSize', this.rowSize.toString());
	}

}
