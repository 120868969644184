export enum EModuli {
	tappe = 'tappe',
	program = 'program',
	sms = 'sms',
	qrcode = 'qrcode',
	masterclass = 'masterclass',
	notifications = 'notifications',
	appSupport = 'appSupport',
	whatsappSupport = 'whatsappSupport',
	qrcodeScanner = 'qrcodeScanner',
	qrCodeMasterclass = 'qrcodeMasterclass',
	contattiUtili = 'contattiUtili',
	mappe = 'mappe',
	webappSupport = 'webappSupport',
	webappWhatsapp = 'webappWhatsapp',
	premi = 'premi'
}

export const EModuliToArray = (): string[] => {
	const keys: string[] = [];
	Object.keys(EModuli).map(r => keys.push(r));
	return keys;
};
