import pkg from '../../package.json';

export const environment = {
	appVersion: pkg.version + '-dev',
	useEmulators: false,
	production: false,
	storage: 'gevents_dev',
	socketWhatsapp: {
		port: 3900,
		token: '9b1b6c66-65b5-4569-b8dc-dda1c7116cc3'
	},
	socketEmail: {
		port: 3200,
		token: '3SZWldpRvM1pAgC3fdew4qa3SqYHNAlG7kQ7KQm6vSxRfKpbxO0IJeiPUR8AJP6l'
	},
	firebaseConfig: {
		apiKey: 'AIzaSyBEabOdnXkU6wgbQyJ3hDbff9RyEC_aqDM',
		authDomain: 'gevents-dev.firebaseapp.com',
		projectId: 'gevents-dev',
		storageBucket: 'gevents-dev.appspot.com',
		messagingSenderId: '108633849925',
		appId: '1:108633849925:web:1771eed1ee1d9bf9d3632c',
		measurementId: 'G-2R31LVG65T',
		vapidKey: 'AAAAPK8Qu4I:APA91bFwwy53qaEZnXtqmCtpnPUZui4xhL1NP7ix_9wFtbwhSYKlBSbtP4L2WahX2my73w67GegK44iIB0JlILWElmpWRHxoH0lCZhdBlL-KUXXbHWO8Gd-zfWF21hm7F5fzBKrpm2WT'
	}
};
